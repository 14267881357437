<template>
  <div class="event-navigator-container" v-if="eventData.loaded">
    <TjingHorizontalNotification
      color="sunrise"
      class="mb-4"
      v-if="showWaitingListBanner"
    >
      <Warning />
      <p class="information">Players on waiting list</p>
      <TjingClickToolTip>
        <p>
          Add a player from the waiting list to a group by going to the ‘Manage
          groups’ page.
        </p>
        <br />
        <p>
          Quick link to
          <span
            class="manager-link"
            @click="
              $router.push({
                name: 'event-groups-round',
                params: { roundId: eventData.data.rounds[0].id },
              })
            "
            >Manage groups</span
          >
        </p>
      </TjingClickToolTip>
    </TjingHorizontalNotification>

    <EventOverview :eventData="eventData.data" :key="'EventOverview'" />
    <RoundsManager
      :eventData="eventData.data"
      :myCourses="eventData.myCourses"
      :key="'ListSignupNoPools'"
    />
  </div>
</template>

<script>
import TjingClickToolTip from "@/components/UIElements/TjingClickToolTip";
import EventOverview from "@/components/EventOverview";
import RoundsManager from "@/components/RoundsManager";
import TjingHorizontalNotification from "@/components/TjingHorizontalNotification";
import Warning from "@/assets/icons/Warning";

export default {
  name: "EventRoundsNavigator",
  data() {
    return {
      slideDirection: "forward",
      view: null,
      prepareGroupsForRound: null,
      whatToPrepare: "",
    };
  },
  components: {
    EventOverview,
    RoundsManager,
    TjingClickToolTip,
    TjingHorizontalNotification,
    Warning,
  },
  computed: {
    showWaitingListBanner() {
      let showBanner = true;

      if (
        this.eventData.data.registrations.filter((reg) => {
          return reg.status == "PENDING";
        }).length == 0
      ) {
        showBanner = false;
      }

      this.eventData.data.rounds.forEach((round, roundIndex) => {
        if (roundIndex == 0) {
          round.pools.forEach((pool) => {
            if (pool.status != "PREPARE") {
              showBanner = false;
            }
          });
        } else {
          if (round.status == "ONGOING" || round.status == "COMPLETED") {
            showBanner = false;
          }
        }
      });

      return showBanner;
    },
    eventData() {
      return this.$store.getters.managedEvent;
    },

    currentView() {
      let currentView = "";
      let singlePoolEvent = true;
      let listSignupEvent = true;

      if (this.eventData.data.tour.registrationMethod == "GROUPS") {
        listSignupEvent = false;
      }

      this.eventData.data.rounds.forEach((round) => {
        if (round.pools.length > 1) {
          singlePoolEvent = false;
        }
      });

      if (singlePoolEvent && listSignupEvent) {
        return "LIST_NO_POOLS";
      } else if (singlePoolEvent && !listSignupEvent) {
        return "LIST_WITH_POOLS";
      } else if (!singlePoolEvent && !listSignupEvent) {
        return "GROUPS_WITH_POOLS";
      } else if (!singlePoolEvent && listSignupEvent) {
        return "GROUPS_NO_POOLS";
      }
    },
  },
  methods: {
    loadEventData() {
      this.$store.dispatch(
        "loadManagedEventData",
        this.$router.currentRoute.params.eventId
      );
    },
  },
  beforeMount() {
    this.loadEventData();
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "../styles/EventRoundStyles.scss";

.manager-link {
  color: $ocean;
  @include Gilroy-Bold;
  cursor: pointer;
}

a {
  color: $teal;

  &:hover {
    text-decoration: underline;
  }
}

.event-info-wrapper {
  display: flex;
  flex-direction: column;

  .event-info-section {
    &:not(:first-child) {
      margin-top: 32px;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        font-size: 14px;
        align-items: center;
        @include Gilroy-Bold;

        .disabled-icon {
          .a,
          .b {
            fill: none;
          }
          .b {
            stroke: #ff0064;
            stroke-width: 2px;
          }
          .c,
          .d {
            stroke: none;
          }
          .d {
            fill: #ff0064;
          }
        }

        .enabled-icon {
          .a,
          .b {
            fill: none;
          }
          .b {
            stroke: #05b5bc;
            stroke-width: 2px;
          }
          .c,
          .d {
            stroke: none;
          }
          .d {
            fill: #05b5bc;
          }
        }
        svg {
          margin-right: 8px;
          height: 24px;
        }
      }
    }
  }
  .left {
    width: 100%;
    padding: 30px 15px 0px 15px;
  }
  .right {
    border-left: 1px solid $fog;
    padding: 30px 15px 32px 15px;
    width: 100%;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .event-info-wrapper {
    display: flex;
    flex-direction: row;

    .event-info-section {
      &:not(:first-child) {
        margin-top: 32px;
      }

      ul {
        list-style: none;
        display: flex;
        justify-content: flex-start;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          margin-right: 32px;
          font-size: 14px;
          align-items: center;
          @include Gilroy-Bold;
        }
      }
    }
    .left {
      width: 50%;
      padding: 30px 30px 40px 30px;
      border-bottom: 0px solid $fog;
    }
    .right {
      border-left: 1px solid $fog;
      padding: 30px 30px 40px 30px;
      width: 50%;
    }
  }
}
</style>
