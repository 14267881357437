<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
    <path
      class="a"
      d="M-508.5,1987.159v9.682a2.307,2.307,0,0,0,1.191,2.007l8.618,4.841a2.442,2.442,0,0,0,2.382,0l8.618-4.841a2.307,2.307,0,0,0,1.191-2.007v-9.682a2.306,2.306,0,0,0-1.191-2.007l-8.618-4.841a2.44,2.44,0,0,0-2.382,0l-8.618,4.841A2.306,2.306,0,0,0-508.5,1987.159Z"
      transform="translate(508.5 -1980)"
    />
    <line class="b" x2="8" transform="translate(7 17)" />
    <path
      class="c"
      d="M2868.235,146.251v8h-4"
      transform="translate(-1909.248 -2124.735) rotate(45)"
    />
  </svg>
</template>

<script>
export default {
  name: "Signature"
};
</script>

<style lang="scss" scoped>
.a {
  fill: #0af;
}
.b,
.c {
  fill: none;
  stroke: #fff;
}
.b {
  stroke-miterlimit: 10;
}
.c {
  stroke-width: 2px;
}
</style>