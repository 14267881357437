<template>
  <div class="component-wrapper">
    <template v-if="readOnly">
      <div class="date-mode" v-if="options.dateOnly">
        <div class="display read-only">
          <div class="input">
            <img src="@/assets/img/calendar-elephant.svg" class="calendar" />
            <span>{{ formattedDate }}</span>
          </div>
        </div>
      </div>
      <div class="time-mode" v-else-if="options.timeOnly">
        <div class="display read-only">
          <div class="input">
            <img src="@/assets/img/time-elephant.svg" class="clock" />
            <span>{{ formattedTime }}</span>
          </div>
        </div>
      </div>
      <div class="date-time-mode" v-else>
        <div class="display read-only">
          <div class="input">
            <img src="@/assets/img/calendar-elephant.svg" class="calendar" />
            <span>{{ formattedDate }}</span>
            <img src="@/assets/img/time-elephant.svg" class="clock" />
            <span>{{ formattedTime }}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="picker-container" :class="{ grey: options.mode == 'grey' }">
        <div class="date-mode" v-if="options.dateOnly">
          <div class="display">
            <div v-show="dateTime == null" class="empty-input">
              <img src="@/assets/img/calendar-midnight.svg" />
              Select date
            </div>
            <div class="input" v-show="dateTime !== null">
              <img src="@/assets/img/calendar-elephant.svg" class="calendar" />
              <span>{{ formattedDate }}</span>
              <img
                src="@/assets/img/close-dark.svg"
                alt
                class="clear"
                @click.stop="clear()"
              />
            </div>
          </div>
        </div>
        <div class="time-mode" v-else-if="options.timeOnly">
          <div class="display">
            <div v-show="dateTime === null" class="empty-input">
              <img src="@/assets/img/time-elephant.svg" />
              Select time
            </div>
            <div class="input" v-show="dateTime !== null">
              <img src="@/assets/img/time-elephant.svg" class="clock" />
              <span>{{ formattedTime }}</span>
              <img
                src="@/assets/img/close-dark.svg"
                alt
                class="clear"
                @click.stop="clear()"
              />
            </div>
          </div>
        </div>
        <div class="date-time-mode grey" v-else>
          <div class="display" v-if="options.mode == 'standard'">
            <div v-show="dateTime == null" class="empty-input">
              <img src="@/assets/img/calendar-midnight.svg" />
              Select date and time
            </div>
            <div
              class="input"
              v-show="dateTime !== null && dateTime !== 'undefined'"
            >
              <img src="@/assets/img/calendar-elephant.svg" class="calendar" />
              <span>{{ formattedDate }}</span>
              <img src="@/assets/img/time-elephant.svg" class="clock" />
              <span>{{ formattedTime }}</span>
              <img
                src="@/assets/img/close-dark.svg"
                alt
                class="clear"
                @click.stop="clear()"
              />
            </div>
          </div>
          <div class="display grey" v-else-if="options.mode == 'grey'">
            <div v-show="dateTime == null" class="empty-input">
              Select start time
            </div>
            <div
              class="input"
              v-show="dateTime !== null && dateTime !== 'undefined'"
            >
              <span>{{ formattedDateTime }}</span>
            </div>
          </div>
        </div>
        <div
          class="picker-wrapper"
          :class="{ visible: pickerVisible || pickerOpen }"
        >
          <div class="header" v-if="!options.timeOnly">
            <div class="decrease" @click="monthModifier--">
              <img src="@/assets/img/collapse-dark.svg" class="left" />
            </div>
            <div class="active-month">{{ activeMonth }}</div>
            <div class="increase" @click="monthModifier++">
              <img src="@/assets/img/collapse-dark.svg" class="right" />
            </div>
          </div>
          <div class="body" v-if="!options.timeOnly">
            <div class="title">Mon</div>
            <div class="title">Tue</div>
            <div class="title">Wed</div>
            <div class="title">Thur</div>
            <div class="title">Fri</div>
            <div class="title">Sat</div>
            <div class="title">Sun</div>

            <div
              class="previous-month day"
              v-for="day in daysInPreviousMonthToShow"
              :key="'oldday' + day.date()"
            ></div>

            <div
              class="this-month day disabled"
              v-for="day in disabledDaysAtStartOfMonth"
              :key="'disabledearly' + day.date()"
            >
              {{ day.format("D") }}
            </div>

            <div
              class="this-month day enabled"
              :class="{ 'selected-day': selectedDay(day) }"
              v-for="day in daysInActiveMonthToShow"
              :key="'currentdays' + day.date()"
              @click="pickDate(day)"
            >
              {{ day.format("D") }}
            </div>

            <div
              class="this-month day disabled"
              v-for="day in disabledDaysAtEndOfMonth"
              :key="'disabledlate' + day.date()"
            >
              {{ day.format("D") }}
            </div>

            <div
              class="next-month day"
              v-for="day in daysInNextMonthToShow"
              :key="'futureday' + day.date()"
            ></div>
          </div>
          <div
            class="body time-picker"
            :class="{ 'time-only': options.timeOnly }"
            v-if="!options.dateOnly"
          >
            <div class="image">
              <img src="@/assets/img/time-dark.svg" />
            </div>
            <div class="hours">
              <div
                @click="increase('hours')"
                :class="{ disabled: inputDisabled('inc-hr') }"
              >
                <Chevron direction="top" color="ocean" />
              </div>
              <input
                type="tel"
                :value="fortmattedHours"
                @blur="validateHour()"
                @keypress.enter="validateHour()"
                :class="{ invalid: invalidHour }"
                ref="hours"
              />
              <div
                @click="decrease('hours')"
                :class="{ disabled: inputDisabled('dec-hr') }"
              >
                <Chevron direction="down" color="ocean" />
              </div>
            </div>
            <div class="separator">:</div>
            <div class="minutes">
              <div
                @click="increase('minutes')"
                :class="{ disabled: inputDisabled('inc-min') }"
              >
                <Chevron direction="top" color="ocean" />
              </div>

              <input
                type="tel"
                :value="formattedMinutes"
                @blur="validateMinute()"
                @keypress.enter="validateMinute()"
                :class="{ invalid: invalidMinute }"
                ref="minutes"
              />
              <div
                @click="decrease('minutes')"
                :class="{ disabled: inputDisabled('dec-min') }"
              >
                <Chevron direction="down" color="ocean" />
              </div>
            </div>
            <div
              class="image info"
              @click="showOverlay = true"
              v-if="!options.timeOnly"
            >
              <img src="@/assets/img/info-dark.svg" />
            </div>
            <div class="image" v-else></div>
          </div>
          <div class="footer">
            <StandardBorderedButton
              title="Cancel"
              :fluid="true"
              @click="cancelChange()"
            />
            <StandardSolidButton
              title="Save"
              :fluid="true"
              :disabled="temporaryDateTime == null"
              @click="applyDate()"
            />
          </div>
          <div class="overlay" :class="{ visible: showOverlay }">
            <h3>Restrictions</h3>
            <p>
              Due to the settings you have selected there are some restrictions
              on what times you can select.
            </p>
            <p>
              The earliest available date is:
              <span>{{
                standardDateTimeFormat(this.options.minDateTime)
              }}</span>
            </p>
            <p>
              The latest selectable date is:
              <span>{{
                standardDateTimeFormat(this.options.maxDateTime)
              }}</span>
            </p>
            <img
              src="@/assets/img/close-dark.svg"
              @click="showOverlay = false"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import ClickOutside from "vue-click-outside";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import Chevron from "@/assets/icons/Chevron";

export default {
  name: "EventRoundsPoolTimeDatePicker",
  props: ["dateTime", "options", "readOnly", "pickerOpen"],
  directives: {
    ClickOutside,
  },
  components: {
    StandardBorderedButton,
    StandardSolidButton,
    Chevron,
  },
  data() {
    return {
      monthModifier: 0,
      temporaryDateTime: null,
      internalDateTime: null,
      invalidHour: false,
      invalidMinute: false,
      pickerVisible: false,
      showOverlay: false,
    };
  },
  watch: {
    pickerOpen: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.pickerVisible = true;
        } else {
          this.close();
        }
      },
    },
    dateTime: {
      immediate: true,
      handler(val, oldVal) {
        if (this.options.timeOnly) {
          moment(val).isValid()
            ? (this.temporaryDateTime = val.clone())
            : (this.temporaryDateTime = moment());
        } else if (this.options.dateOnly) {
          moment(val).isValid()
            ? (this.temporaryDateTime = val.clone())
            : (this.temporaryDateTime = moment());

          moment(val).isValid()
            ? (this.internalDateTime = val.clone())
            : (this.internalDateTime = moment().startOf("minute"));
        } else {
          moment(val).isValid()
            ? (this.temporaryDateTime = moment(val).clone())
            : null;

          moment(val).isValid()
            ? (this.internalDateTime = moment(val).clone())
            : (this.internalDateTime = moment().startOf("minute"));
        }
      },
    },
  },
  methods: {
    standardDateTimeFormat(dateTime) {
      return moment(dateTime).format("dddd DD MMM YYYY [at] HH:mm");
    },
    close() {
      this.pickerVisible = false;
      this.$emit("close");
    },
    validateHour() {
      this.temporaryDateTime = moment(this.temporaryDateTime).hour(
        this.$refs.hours.value
      );
    },
    validateMinute() {
      this.temporaryDateTime = moment(this.temporaryDateTime).minute(
        this.$refs.minutes.value
      );
    },
    markInvalidMinute() {
      this.invalidMinute = true;
      setTimeout(() => {
        this.invalidMinute = false;
      }, 600);
    },
    markInvalidHour() {
      this.invalidHour = true;
      setTimeout(() => {
        this.invalidHour = false;
      }, 600);
    },
    inputDisabled(type) {
      if (type == "inc-min") {
        if (
          moment(this.options.maxDateTime).minute() ==
            moment(this.temporaryDateTime).minute() &&
          moment(this.options.maxDateTime).isSame(
            this.temporaryDateTime,
            "day"
          ) &&
          moment(this.options.maxDateTime).isSame(
            this.temporaryDateTime,
            "hour"
          )
        ) {
          return true;
        }
      }
      if (type == "inc-hr") {
        if (
          moment(this.options.maxDateTime).hour() ==
            moment(this.temporaryDateTime).hour() &&
          moment(this.options.maxDateTime).isSame(this.temporaryDateTime, "day")
        ) {
          return true;
        }
      }
      if (type == "dec-min") {
        if (
          moment(this.options.minDateTime).minute() ==
            moment(this.temporaryDateTime).minute() &&
          moment(this.options.minDateTime).isSame(
            this.temporaryDateTime,
            "day"
          ) &&
          moment(this.options.minDateTime).isSame(
            this.temporaryDateTime,
            "hour"
          )
        ) {
          return true;
        }
      }
      if (type == "dec-hr") {
        if (
          moment(this.options.minDateTime).hour() ==
            moment(this.temporaryDateTime).hour() &&
          moment(this.options.minDateTime).isSame(this.temporaryDateTime, "day")
        ) {
          return true;
        }
      } else return false;
    },
    increase(type) {
      if (this.options.timeOnly) {
        this.temporaryDateTime = moment(this.temporaryDateTime).add(1, type);
      } else {
        if (
          this.options.maxDateTime == null ||
          moment(this.temporaryDateTime)
            .add(1, type)
            .isBefore(this.options.maxDateTime) ||
          moment(this.temporaryDateTime)
            .add(1, type)
            .isSame(this.options.maxDateTime, "minute")
        ) {
          this.temporaryDateTime = moment(this.temporaryDateTime).add(1, type);
        }
      }
    },
    decrease(type) {
      if (this.options.timeOnly) {
        this.temporaryDateTime = moment(this.temporaryDateTime).subtract(
          1,
          type
        );
      } else {
        if (
          this.options.minDateTime == null ||
          moment(this.temporaryDateTime)
            .subtract(1, type)
            .isAfter(this.options.minDateTime) ||
          moment(this.temporaryDateTime)
            .subtract(1, type)
            .isSame(this.options.minDateTime, "minute")
        ) {
          this.temporaryDateTime = moment(this.temporaryDateTime).subtract(
            1,
            type
          );
        }
      }
    },
    selectedDay(day) {
      return day.isSame(this.temporaryDateTime, "day");
    },
    applyDate() {
      this.$emit("update", this.temporaryDateTime);
      this.monthModifier = 0;
      this.close();
    },
    cancelChange() {
      if (moment(this.dateTime).isValid()) {
        this.temporaryDateTime = moment(this.dateTime);
      } else {
        this.temporaryDateTime = moment();
      }

      this.close();
    },
    clear() {
      this.$emit("update", null);
      this.close();
    },
    pickDate(date) {
      if (this.temporaryDateTime == null) {
        this.temporaryDateTime = moment(date);
      } else {
        if (
          moment(this.temporaryDateTime)
            .year(date.year())
            .month(date.month())
            .date(date.date())
            .isAfter(moment(this.options.maxDateTime))
        ) {
          this.markInvalidMinute();
          this.markInvalidHour();
          this.temporaryDateTime = moment(this.temporaryDateTime)
            .year(date.year())
            .month(date.month())
            .date(date.date())
            .minute(this.options.maxDateTime.minute())
            .hour(this.options.maxDateTime.hour());
        } else if (
          moment(this.temporaryDateTime)
            .year(date.year())
            .month(date.month())
            .date(date.date())
            .isBefore(moment(this.options.minDateTime))
        ) {
          this.markInvalidMinute();
          this.markInvalidHour();
          this.temporaryDateTime = moment(this.temporaryDateTime)
            .year(date.year())
            .month(date.month())
            .date(date.date())
            .minute(this.options.minDateTime.minute())
            .hour(this.options.minDateTime.hour());
        } else {
          this.temporaryDateTime = moment(this.temporaryDateTime)
            .year(date.year())
            .month(date.month())
            .date(date.date());
        }
      }
    },
  },
  computed: {
    formattedDateTime() {
      if (
        moment(this.dateTime).isValid() &&
        typeof this.dateTime !== "undefined"
      ) {
        if (this.$store.state.utility.deviceWidth > 360) {
          return moment(this.dateTime).format("ddd, DD MMM YYYY [at] HH:mm");
        } else {
          return moment(this.dateTime).format("ddd, DD MMM YYYY [at] HH:mm");
        }
      } else {
        return "";
      }
    },
    formattedDate() {
      if (
        moment(this.dateTime).isValid() &&
        typeof this.dateTime !== "undefined"
      ) {
        if (this.$store.state.utility.deviceWidth > 360) {
          return this.dateTime.format("dddd DD MMMM YYYY");
        } else {
          return this.dateTime.format("dddd DD MMM YYYY");
        }
      } else {
        return "";
      }
    },
    formattedTime() {
      if (
        moment(this.dateTime).isValid() &&
        typeof this.dateTime !== "undefined"
      ) {
        return this.dateTime.format("HH:mm");
      } else {
        return "";
      }
    },
    fortmattedHours() {
      if (moment(this.temporaryDateTime).isValid()) {
        return this.temporaryDateTime.format("HH");
      } else {
        return "00";
      }
    },
    formattedMinutes() {
      if (moment(this.temporaryDateTime).isValid()) {
        return this.temporaryDateTime.format("mm");
      } else {
        return "00";
      }
    },
    disabledDaysAtEndOfMonth() {
      let dayList = [];
      if (this.options.maxDateTime == null) {
        return dayList;
      } else {
        for (
          let index = 1;
          index <=
          moment(this.internalDateTime)
            .clone()
            .add(this.monthModifier, "months")
            .daysInMonth();
          index++
        ) {
          if (
            this.internalDateTime
              .clone()
              .add(this.monthModifier, "months")
              .date(index)
              .isAfter(moment(this.options.maxDateTime).endOf("day"))
          ) {
            dayList.push(
              this.internalDateTime
                .clone()
                .add(this.monthModifier, "months")
                .date(index)
            );
          }
        }

        return dayList;
      }
    },
    disabledDaysAtStartOfMonth() {
      let dayList = [];
      if (this.options.minDateTime == null) {
        return dayList;
      } else {
        for (
          let index = 1;
          index <=
          moment(this.internalDateTime)
            .clone()
            .add(this.monthModifier, "months")
            .daysInMonth();
          index++
        ) {
          if (
            this.internalDateTime
              .clone()
              .add(this.monthModifier, "months")
              .date(index)
              .isBefore(moment(this.options.minDateTime).startOf("day"))
          ) {
            dayList.push(
              this.internalDateTime
                .clone()
                .add(this.monthModifier, "months")
                .date(index)
            );
          }
        }

        return dayList;
      }
    },
    daysInActiveMonthToShow() {
      let dayList = [];
      for (
        let index = 1;
        index <=
        moment(this.internalDateTime)
          .clone()
          .add(this.monthModifier, "months")
          .daysInMonth();
        index++
      ) {
        if (
          this.options.minDateTime == null &&
          this.options.maxDateTime == null
        ) {
          dayList.push(
            this.internalDateTime
              .clone()
              .add(this.monthModifier, "months")
              .date(index)
          );
        } else if (this.options.minDateTime == null) {
          if (
            this.internalDateTime
              .clone()
              .add(this.monthModifier, "months")
              .date(index)
              .isBefore(moment(this.options.maxDateTime).endOf("day"))
          ) {
            dayList.push(
              this.internalDateTime
                .clone()
                .add(this.monthModifier, "months")
                .date(index)
            );
          }
        } else if (this.options.maxDateTime == null) {
          if (
            this.internalDateTime
              .clone()
              .add(this.monthModifier, "months")
              .date(index)
              .isAfter(moment(this.options.minDateTime).startOf("day"))
          ) {
            dayList.push(
              this.internalDateTime
                .clone()
                .add(this.monthModifier, "months")
                .date(index)
            );
          }
        } else if (
          this.internalDateTime
            .clone()
            .add(this.monthModifier, "months")
            .date(index)
            .isAfter(moment(this.options.minDateTime).startOf("day")) &&
          this.internalDateTime
            .clone()
            .add(this.monthModifier, "months")
            .date(index)
            .isBefore(moment(this.options.maxDateTime).endOf("day"))
        ) {
          dayList.push(
            this.internalDateTime
              .clone()
              .add(this.monthModifier, "months")
              .date(index)
          );
        }
      }

      return dayList;
    },
    daysInPreviousMonthToShow() {
      let dayList = [];
      for (
        let index = 0;
        index <
        this.internalDateTime
          .clone()
          .add(this.monthModifier, "months")
          .subtract(1, "months")
          .daysInMonth();
        index++
      ) {
        dayList.push(
          this.internalDateTime
            .clone()
            .add(this.monthModifier, "months")
            .subtract(1, "months")
            .date(index + 1)
        );
      }

      return dayList
        .reverse()
        .slice(
          0,
          this.activeMonthStartsOnDay == 0 ? 6 : this.activeMonthStartsOnDay - 1
        )
        .reverse();
    },
    daysInNextMonthToShow() {
      let dayList = [];
      for (
        let index = 1;
        index <=
        this.internalDateTime
          .clone()
          .add(this.monthModifier, "months")
          .add(1, "months")
          .daysInMonth();
        index++
      ) {
        dayList.push(
          this.internalDateTime
            .clone()
            .add(this.monthModifier, "months")
            .add(1, "months")
            .date(index)
        );
      }
      if (this.activeMonthEndsOnDay == 0) {
        return [];
      } else {
        return dayList.slice(0, 7 - this.activeMonthEndsOnDay);
      }
    },
    activeMonthStartsOnDay() {
      return this.internalDateTime
        .clone()
        .add(this.monthModifier, "months")
        .startOf("month")
        .day();
    },
    activeMonthEndsOnDay() {
      return this.internalDateTime
        .clone()
        .add(this.monthModifier, "months")
        .endOf("month")
        .day();
    },
    activeMonth() {
      return moment(this.internalDateTime)
        .clone()
        .add(this.monthModifier, "months")
        .format("MMMM YYYY");
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.picker-container {
  position: relative;
  height: 50px;

  &.grey {
    height: auto;

    .picker-wrapper {
      top: 30px;
      left: -30px;
      .time-picker {
        justify-content: space-around;
        .image {
          margin: 15px;
        }
      }
    }
  }
}
input {
  outline: none;
}
.date-time-mode,
.date-mode,
.time-mode {
  position: relative;
  height: 50px;

  &.grey {
    height: 24px;
  }
}
.display {
  width: 100%;
  cursor: pointer;
  user-select: none;

  &.grey {
    .input {
      border: 0px solid $mouse;
      padding: 0;
      height: 24px;
      font-size: 14px;
      span {
        margin: 0;
      }
    }

    .empty-input {
      border: 0px solid $mouse;
      padding: 0;
      height: 24px;
      span {
        margin: 0;
      }
    }
  }

  &.read-only {
    cursor: default;
  }
  .input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    border: 1px solid $mouse;
    border-radius: 4px;
    height: 50px;
    font-size: 14px;

    font-size: 16px;
    line-height: 22px;
    @include Gilroy-Regular;
    color: $midnight;
    margin: 0;

    .clear {
      margin-left: auto;
    }

    span {
      margin: 0px 11px;
    }

    img {
      height: 16px;
    }
  }
  .empty-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    border: 1px solid $mouse;
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 4px;
    @include Gilroy-Bold;
    img {
      margin-right: 11px;
    }
  }
}

.grey {
  .picker-wrapper {
    border: 0px solid rgba(0, 0, 0, 0);
  }
}

.picker-wrapper {
  position: absolute;
  border-radius: 4px;
  top: 55px;
  left: -25px;
  height: auto;
  width: 100%;
  max-height: 0;
  background-color: white;

  overflow: hidden;
  box-shadow: 0 1px 4px transparent;
  z-index: 2;
  border: 0px solid white;
  min-width: 310px;
  max-width: 400px;

  &.visible {
    max-height: 750px;
    box-shadow: 0 1px 4px $mouse;
    background-color: white;
    border: 1px solid $mist;
    min-width: 310px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    opacity: 0;
    transition: all ease 0.3s;
    color: $midnight;
    font-size: 30px;
    opacity: 0;
    transition-delay: 100ms;
    z-index: -1;
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    border: 1px solid transparent;
    p {
      span {
        @include Gilroy-Bold;
        display: flex;
      }
    }

    img {
      position: absolute;
      top: 19px;
      right: 15px;
      height: 16px;
      cursor: pointer;
    }

    &.visible {
      display: flex;
      opacity: 1;
      z-index: 30;
      transition-delay: 0ms;
    }
  }

  .header {
    display: flex;
    @include Gilroy-Bold;
    justify-content: space-between;
    padding: 10px 20px;
    margin-top: 10px;
    opacity: 1;

    .left {
      transform: rotate(-90deg);
      height: 16px;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
    .right {
      transform: rotate(90deg);
      height: 16px;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .body {
    padding: 0px 10px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    .title {
      font-size: 12px;
      color: $wolf;
      text-transform: uppercase;
      @include Gilroy-Regular;
    }

    .this-month.enabled {
      &.selected-day {
        background-color: $twilight;
        color: white;

        &:hover {
          border-radius: 50%;
          background-color: $twilightshade;
          color: white;
        }
      }
      &:hover {
        background-color: $halfmouse;
        cursor: pointer;
        color: $midnight;
      }
    }
    .day {
      @include Gilroy-Bold;
      font-size: 16px;

      &.disabled {
        cursor: default;
        color: $mouse;
      }
    }
    .day,
    .title {
      display: flex;
      width: 14.285%;
      margin: 5px calc(7.1425% - 15px);
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition: all ease 0.3s;

      user-select: none;
      &.previous-month {
        color: $mouse;
      }
      &.next-month {
        color: $mouse;
      }
    }
  }
  .time-picker {
    border-top: 1px solid $mouse;

    &.time-only {
      border-top: 0px solid $mouse;
    }

    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      width: 60px;
      height: 50px;
      border-radius: 4px;
      border: 1px solid $mouse;
      text-align: center;
      font-size: 16px;
      @include Gilroy-Bold;
      transition: all ease 0.5s;

      &.invalid {
        border: 2px solid $watermelon;
      }
    }
    .disabled {
      opacity: 0.15 !important;
    }
    .separator {
      @include Gilroy-Bold;
      margin: 0 15px;
    }
    .hours,
    .minutes {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        margin: 15px 0;
        cursor: pointer;
      }

      img {
        height: 16px;
        margin: 15px 0;
        transition: all ease 0.3s;
        opacity: 1;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .image {
      width: 24px;
      margin: 0 5px;

      &.info {
        cursor: pointer;
      }

      img {
        width: 24px;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $mouse;
    padding: 30px 15px;

    div {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      @include Gilroy-Bold;
      font-size: 16px;
      cursor: pointer;

      &.cancel {
        color: $watermelon;
      }
      &.apply {
        color: $avocado;
      }
    }
  }
}

@media (min-width: 350px) {
  .picker-container {
    &.grey {
      .picker-wrapper {
        min-width: 100%;
        left: 0px;
      }
    }
    .picker-wrapper {
      left: 0px;
      min-width: 100%;
      top: 60px;

      .time-picker {
        justify-content: center;
        .image {
          margin: 0 15px;
        }
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .picker-container {
    &.grey {
      .input {
        font-size: 16px;
      }
      .picker-wrapper {
        min-width: 325px;
        left: 0px;
      }
    }
    .picker-wrapper {
      max-width: 350px;
      min-width: 350px;

      .body {
        .day,
        .title {
          display: flex;
          width: 14.285%;
          margin: 0px calc(7.1425% - 20px);
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;

          user-select: none;
          &.previous-month {
            color: $mouse;
          }
          &.next-month {
            color: $mouse;
          }
        }
      }
    }
  }
}
</style>
