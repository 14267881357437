<template>
  <div class="rounds">
    <div
      class="round"
      v-for="(round, roundIndex) in eventData.rounds"
      :key="round.id"
    >
      <div
        class="section pool"
        v-for="(pool, poolIndex) in round.pools"
        :class="{ collapsed: collapsedPools.includes(pool.id) }"
        :key="pool.id"
      >
        <div class="header" @click="toggleCollapsedPool(pool.id)">
          <div class="pool-status" :class="pool.status"></div>
          <h4>
            Round {{ roundIndex + 1 }}
            <span v-if="round.pools.length > 1">
              - Pool {{ $store.state.poolConversion[poolIndex] }}</span
            >
          </h4>

          <CollapseExpandArrow />
        </div>
        <slide-up-down
          tag="div"
          :active="collapsedPools.includes(pool.id) == false"
          :duration="350"
        >
          <div class="content-wrapper">
            <div class="content">
              <div class="sub-section">
                <h5>
                  When
                  <StandardSmallSolidButton
                    :fluid="true"
                    @click="openPicker(pool)"
                    v-if="pool.status == 'CLOSED' || pool.status == 'PREPARE'"
                    :title="visiblePoolDatePicker == pool.id ? 'Close' : 'Edit'"
                  />
                </h5>
                <EventRoundsPoolTimeDatePicker
                  :pickerOpen="visiblePoolDatePicker == pool.id"
                  :readOnly="false"
                  :options="poolDateTimeOptions(roundIndex, round, 0, pool)"
                  :dateTime="poolDateTime(pool.date)"
                  @close="visiblePoolDatePicker = null"
                  @update="updatePoolDateTime($event, pool)"
                />
              </div>
              <div class="sub-section pb-0">
                <h5 class="mb-0">
                  Where
                  <StandardSmallSolidButton
                    :fluid="true"
                    v-if="
                      pool.status == 'CLOSED' &&
                      pool.groups.length == 0 &&
                      !editableCourses.includes(pool.id)
                    "
                    @click="toggleVisibleCourseSelectors(pool.id)"
                    title="Edit"
                  />
                  <StandardSmallSolidButton
                    :fluid="true"
                    v-if="
                      pool.status == 'CLOSED' &&
                      pool.groups.length == 0 &&
                      editableCourses.includes(pool.id)
                    "
                    @click="toggleVisibleCourseSelectors(pool.id)"
                    title="Done"
                  />
                </h5>
                <div
                  class="layout-update-notice"
                  v-if="
                    newLayoutVersionAvailable(pool.layoutVersion) &&
                    pool.groups.length == 0
                  "
                  @click="toggleLayoutReview(pool.id)"
                >
                  <Warning />
                  <p>An updated layout is available</p>
                  <span>
                    <template v-if="expandedLayoutReviews.includes(pool.id)"
                      >Hide</template
                    >
                    <template v-else>Review</template>
                  </span>
                </div>

                <slide-up-down
                  :active="expandedLayoutReviews.includes(pool.id)"
                  :duration="350"
                >
                  <div class="layout-review-container">
                    <h5>Current layout applied to this round:</h5>
                    <div class="layout-wrapper">
                      <p class="layout-name">
                        {{ pool.layoutVersion.layout.name }}
                        <Verified
                          v-if="pool.layoutVersion.layout.type == 'PUBLIC'"
                        />
                      </p>
                      <div class="layout-info-tags">
                        <div class="card-label ocean">
                          {{ pool.layoutVersion.holes.length }} Holes
                        </div>
                        <div class="card-label strom">
                          Par {{ parCalc(pool.layoutVersion) }}
                        </div>
                      </div>
                      <div class="layout">
                        <ul
                          class="holes"
                          :class="{ wrap: wrapHoles(pool.layoutVersion.holes) }"
                        >
                          <li
                            class="hole"
                            v-for="hole in pool.layoutVersion.holes"
                            :key="hole.id"
                            :class="{}"
                          >
                            <div class="hole-name">{{ displayHole(hole) }}</div>
                            <div class="hole-par" :class="parEval(hole)">
                              {{ hole.par }}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h5>Updated layout available:</h5>
                    <div class="layout-wrapper">
                      <p class="layout-name">
                        {{ pool.layoutVersion.layout.name }}
                        <Verified
                          v-if="pool.layoutVersion.layout.type == 'PUBLIC'"
                        />
                      </p>
                      <div class="layout-info-tags">
                        <div class="card-label ocean">
                          {{
                            pool.layoutVersion.layout.latestVersion.holes.length
                          }}
                          Holes
                        </div>
                        <div class="card-label strom">
                          Par
                          {{ parCalc(pool.layoutVersion.layout.latestVersion) }}
                        </div>
                      </div>
                      <div class="layout">
                        <ul
                          class="holes"
                          :class="{
                            wrap: wrapHoles(
                              pool.layoutVersion.layout.latestVersion.holes
                            ),
                          }"
                        >
                          <li
                            class="hole"
                            v-for="hole in pool.layoutVersion.layout
                              .latestVersion.holes"
                            :key="hole.id"
                            :class="{}"
                          >
                            <div class="hole-name">{{ displayHole(hole) }}</div>
                            <div class="hole-par" :class="parEval(hole)">
                              {{ hole.par }}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="update-buttons">
                      <StandardBorderedButton
                        title="Use current layout"
                        :fluid="false"
                        :desktopFluidity="true"
                        @click="toggleLayoutReview(pool.id)"
                      />
                      <StandardSolidButton
                        title="Use updated layout"
                        :fluid="false"
                        :desktopFluidity="true"
                        @click="
                          udpdatePoolLayout(
                            pool.id,
                            pool.layoutVersion.layout.latestVersion.id
                          )
                        "
                      />
                    </div>
                  </div>
                </slide-up-down>

                <div v-if="!expandedLayoutReviews.includes(pool.id)">
                  <TheRoundsLayoutPicker
                    :poollength="round.pools.length"
                    :pool="pool"
                    :myCourses="myCourses"
                    :edit="editableCourses.includes(pool.id)"
                    @done="toggleVisibleCourseSelectors(pool.id)"
                  />
                </div>
              </div>
              <div class="sub-section">
                <h5>Starting format</h5>
                <div class="option-wrapper">
                  <DefaultRadioButton
                    :groupname="pool.id + 'starting-options'"
                    :value="pool.startMethod"
                    @change="updatePoolStartMethod(pool, $event)"
                    :options="{
                      title: 'Shotgun',
                      value: 'SHOTGUN',
                      disabled:
                        shotgunStartDisabled(eventData, round) ||
                        pool.status == 'OPEN' ||
                        pool.status == 'COMPLETED',
                      description: '',
                    }"
                  />
                  <DefaultRadioButton
                    :groupname="pool.id + 'starting-options'"
                    :value="pool.startMethod"
                    @change="updatePoolStartMethod(pool, $event)"
                    :options="{
                      title: 'Teetime',
                      value: 'TEETIME',
                      disabled:
                        pool.status == 'OPEN' || pool.status == 'COMPLETED',
                      description: '',
                    }"
                  />
                </div>
              </div>
              <div
                class="sub-section"
                :class="{
                  'error-banner': shotgunStartDisabled(eventData, round),
                }"
              >
                <div
                  class="shotgun-unavailable-banner"
                  v-if="shotgunStartDisabled(eventData, round)"
                >
                  <template>
                    <Error />
                    <p>Shotgun start not possible with current group size.</p>
                  </template>
                </div>
                <h5>
                  Groups
                  <StandardSmallSolidButton
                    :fluid="true"
                    v-if="pool.groups.length > 0"
                    @click="
                      $router.push({
                        name: 'event-groups-round',
                        params: { roundId: round.id },
                      })
                    "
                    title="Edit"
                  />
                </h5>
                <div class="groups-info" v-if="pool.groups.length > 0">
                  <div class="groups-used">
                    <p>
                      <b
                        >{{ poolGroupsInfo(pool.groups).groups }}/{{
                          pool.groups.length
                        }}
                        Groups used</b
                      >
                      ({{ poolGroupsInfo(pool.groups).players }}
                      {{ poolGroupsInfo(pool.groups).playersText }})
                    </p>
                  </div>
                  <div
                    class="signed-players"
                    v-if="eventData.isResultSigningEnabled"
                  >
                    <Signature />
                    <p>
                      <b>
                        {{ poolGroupsInfo(pool.groups).signed }}/{{
                          poolGroupsInfo(pool.groups).players
                        }}
                        Signed
                      </b>
                    </p>
                  </div>
                  <div class="signed-players" v-else>
                    <p>
                      <b>
                        {{ poolGroupsInfo(pool.groups).finishedGroups }}/{{
                          poolGroupsInfo(pool.groups).groups
                        }}
                        Finished groups
                      </b>
                    </p>
                  </div>
                  <div
                    class="dnfed-players"
                    v-if="poolGroupsInfo(pool.groups).dnf > 0"
                  >
                    <Error />
                    <p>
                      <b>
                        {{ poolGroupsInfo(pool.groups).dnf }}
                        DNF
                      </b>
                    </p>
                  </div>

                  <div class="published-status">
                    <h6>Publish groups</h6>
                    <TjingClickToolTip :forcedSize="'small'">
                      <div class="how-to">
                        <p>
                          Publishing groups will make them available on your
                          event’s public page.
                        </p>
                      </div>
                    </TjingClickToolTip>
                    <DefaultToggle
                      v-model="pool.areGroupsPublic"
                      @input="togglePublicGroups(pool)"
                    />
                  </div>
                </div>
                <div
                  class="create-groups-options"
                  v-if="pool.groups.length == 0"
                >
                  <DefaultRadioButton
                    v-for="(option, name) in availableSortOptions(
                      eventData,
                      roundIndex,
                      poolIndex
                    )"
                    :class="option.value"
                    :key="pool.id + name"
                    :groupname="pool.id + 'create-groups-options'"
                    :value="pool.createGroupsMethod"
                    @change="pool.createGroupsMethod = $event"
                    :options="{
                      title: option.title,
                      value: option.value,
                      disabled: option.disabled,
                      description: option.description,
                    }"
                  />
                  <StandardSolidButton
                    title="Create groups"
                    :fluid="false"
                    :desktopFluidity="true"
                    :locked="poolActionsLocked(pool.id)"
                    @click="
                      createGroupActions(eventData, roundIndex, poolIndex)
                    "
                  />
                </div>
                <div class="groups-container" v-if="pool.groups.length > 0">
                  <StandardGroupViewOnly
                    v-for="group in pool.groups"
                    :key="group.id"
                    :group="group"
                    :pool="pool"
                    :roundId="round.id"
                    :startMethod="pool.startMethod"
                    :isResultSigningEnabled="eventData.isResultSigningEnabled"
                  />
                </div>
              </div>
              <div class="sub-section">
                <StandardSolidButton
                  v-if="pool.status == 'CLOSED' && roundIndex == 0"
                  class="start-round"
                  title="Finalise groups"
                  :disabled="pool.groups.length == 0"
                  :fluid="false"
                  :desktopFluidity="true"
                  @click="poolToAction = { round, roundIndex, pool, poolIndex }"
                />
                <StandardSolidButton
                  v-else-if="pool.status == 'CLOSED'"
                  class="start-round"
                  :title="
                    round.pools.length == 1
                      ? 'Start round ' + (roundIndex + 1)
                      : 'Start R' +
                        (roundIndex + 1) +
                        '-P' +
                        $store.state.poolConversion[poolIndex]
                  "
                  :disabled="pool.groups.length == 0"
                  :fluid="false"
                  :desktopFluidity="true"
                  @click="poolToAction = { round, roundIndex, pool, poolIndex }"
                />
                <StandardSolidButton
                  v-else-if="pool.status == 'PREPARE'"
                  class="start-round"
                  :title="
                    round.pools.length == 1
                      ? 'Start round ' + (roundIndex + 1)
                      : 'Start R' +
                        (roundIndex + 1) +
                        '-P' +
                        $store.state.poolConversion[poolIndex]
                  "
                  :disabled="pool.groups.length == 0"
                  :fluid="false"
                  :desktopFluidity="true"
                  @click="poolToAction = { round, roundIndex, pool, poolIndex }"
                />
                <StandardSolidButton
                  v-if="pool.status == 'OPEN'"
                  class="start-round"
                  :title="
                    round.pools.length == 1
                      ? 'Close round ' + (roundIndex + 1)
                      : 'Close R' +
                        (roundIndex + 1) +
                        '-P' +
                        $store.state.poolConversion[poolIndex]
                  "
                  :fluid="false"
                  :desktopFluidity="true"
                  @click="poolToAction = { round, roundIndex, pool, poolIndex }"
                />
              </div>
            </div>
          </div>
        </slide-up-down>
      </div>
    </div>
    <div class="manage-round">
      <div
        v-if="
          eventData.rounds.length > 1 &&
          eventData.rounds[eventData.rounds.length - 1].isDeletable
        "
      >
        <div class="initial" @click="$refs.deleteround.open()">
          <Remove />Delete round
          <span>{{ eventData.rounds.length }}</span>
        </div>
      </div>
      <div @click="addRound()" v-if="eventData.rounds.length < 8">
        <Add />Add another round
      </div>
    </div>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      ref="deleteround"
      hide-close-button
    >
      <h3>Delete round {{ eventData.rounds.length }}?</h3>
      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.deleteround.close()"
        />
        <StandardBorderedButton
          title="Delete"
          :fluid="false"
          @click="deleteRound(eventData.rounds[eventData.rounds.length - 1].id)"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      @close="poolToAction = null"
      :enable-mobile-fullscreen="false"
      ref="poolModal"
      hide-close-button
    >
      <template v-if="poolToAction">
        <template v-if="poolToAction.pool.status == 'OPEN' && markAllAsDnf">
          <h3>Incomplete results</h3>
          <p>
            Some players have not completed this round. Closing will mark them
            as DNF.
          </p>
          <p>You can always remove the DNF later under the Results page.</p>

          <div class="buttons">
            <StandardSolidButton
              title="Cancel"
              :fluid="false"
              @click="
                poolToAction = null;
                markAllAsDnf = false;
              "
            />
            <StandardBorderedButton
              v-if="markAllAsDnf"
              title="Close and DNF"
              :fluid="false"
              @click="closePool(poolToAction, true)"
            />
          </div>
        </template>
        <template v-else-if="poolToAction.pool.status == 'OPEN'">
          <h3>
            {{
              poolToAction.round.pools.length == 1
                ? "Close round " + (poolToAction.roundIndex + 1)
                : "Close R" +
                  (poolToAction.roundIndex + 1) +
                  "-P" +
                  $store.state.poolConversion[poolToAction.poolIndex]
            }}?
          </h3>

          <p>This cannot be undone.</p>

          <div class="buttons">
            <StandardSolidButton
              title="Cancel"
              :fluid="false"
              @click="
                poolToAction = null;
                markAllAsDnf = false;
              "
            />

            <StandardBorderedButton
              title="Close"
              :fluid="false"
              @click="closePool(poolToAction, false)"
            />
          </div>
        </template>
        <template
          v-else-if="
            poolToAction.pool.status == 'CLOSED' && poolToAction.roundIndex == 0
          "
        >
          <h3>
            {{
              poolToAction.round.pools.length == 1
                ? "Finalise groups for round " + (poolToAction.roundIndex + 1)
                : "Finalise groups for R" +
                  (poolToAction.roundIndex + 1) +
                  "-P" +
                  $store.state.poolConversion[poolToAction.poolIndex]
            }}
          </h3>
          <template v-if="eventData.tour.registrationMethod == 'GROUPS'">
            <p>
              Once finalised, players will no longer be able to unregister or
              change group themselves. Only an admin will be able to do that.
              Players will still be able to register for the event and will be
              placed on a waiting list.
            </p>
            <p>
              Players will still be able to register for the event and will be
              placed on a waiting list.
            </p>
          </template>
          <p v-else>
            Once finalised, players will no longer be able to unregister
            themselves. Only an admin will be able to remove a player.
          </p>

          <div class="buttons">
            <StandardSolidButton
              title="Cancel"
              :fluid="false"
              @click="poolToAction = null"
            />
            <StandardBorderedButton
              class="forwards"
              title="Finalise"
              :fluid="false"
              @click="finalizeGroups(poolToAction)"
            />
          </div>
        </template>
        <template
          v-else-if="
            poolToAction.pool.status == 'CLOSED' ||
            poolToAction.pool.status == 'PREPARE'
          "
        >
          <h3>
            {{
              poolToAction.round.pools.length == 1
                ? "Start round " + (poolToAction.roundIndex + 1)
                : "Start R" +
                  (poolToAction.roundIndex + 1) +
                  "-P" +
                  $store.state.poolConversion[poolToAction.poolIndex]
            }}
          </h3>
          <p v-if="poolPlayerCount(poolToAction.pool) == 0">
            There are no players in this pool. Are you sure you want to start?
          </p>
          <p>
            Once started you cannot change the course layout or move players
            between groups.
          </p>

          <div class="buttons">
            <StandardSolidButton
              title="Cancel"
              :fluid="false"
              @click="poolToAction = null"
            />
            <StandardBorderedButton
              title="Start"
              :fluid="false"
              @click="startPool(poolToAction.pool)"
            />
          </div>
        </template>
      </template>
    </sweet-modal>
  </div>
</template>

<script>
import EventRoundsPoolTimeDatePicker from "@/components/EventRoundsPoolTimeDatePicker";
import CollapseExpandArrow from "@/assets/icons/CollapseExpandArrow";
import StandardGroupViewOnly from "@/components/StandardGroupViewOnly";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import Add from "@/assets/icons/Add";
import Remove from "@/assets/icons/Remove";
import moment from "moment";
import ClickOutside from "vue-click-outside";
import SlideUpDown from "vue-slide-up-down";
import Warning from "@/assets/icons/Warning";
import Error from "@/assets/icons/Error";
import Verified from "@/assets/icons/Verified";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";
import StandardSmallSolidButton from "@/components/UIElements/StandardSmallSolidButton";
import TheRoundsLayoutPicker from "@/components/TheRoundsLayoutPicker";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import TjingClickToolTip from "@/components/UIElements/TjingClickToolTip";
import Signature from "@/assets/icons/Signature";

export default {
  name: "RoundsManager",
  data() {
    return {
      loaded: false,
      preventFurtherPoolActions: [],
      poolToAction: null,
      visiblePoolDatePicker: "",
      shownCourseSelector: null,
      showVerifyClosePool: [],
      editableCourses: [],
      collapsedPools: [],
      expandedLayoutReviews: [],
      startOption: "TEETIME",
      markAllAsDnf: false,
    };
  },
  directives: {
    ClickOutside,
  },
  props: ["eventData", "myCourses"],
  components: {
    CollapseExpandArrow,
    EventRoundsPoolTimeDatePicker,
    SlideUpDown,
    StandardGroupViewOnly,
    StandardSolidButton,
    StandardBorderedButton,
    Add,
    Remove,
    DefaultRadioButton,
    StandardSmallSolidButton,
    TheRoundsLayoutPicker,
    Warning,
    Verified,
    Error,
    DefaultToggle,
    TjingClickToolTip,
    Signature,
  },
  watch: {
    poolToAction: function (newVal) {
      this.$nextTick(() => {
        newVal == null
          ? this.$refs.poolModal.close()
          : this.$refs.poolModal.open();
      });
    },
  },
  computed: {
    isMobile() {
      if (this.$store.state.utility.deviceWidth < 768) {
        return true;
      }
      return false;
    },
  },
  methods: {
    poolPlayerCount(pool) {
      let count = 0;

      pool.groups.forEach((group) => {
        group.playerConnections.forEach((player) => {
          count++;
        });
      });

      return count;
    },
    saveCollapsedPools() {
      const eventId = this.eventData.id;
      let state = {
        eventId: eventId,
        date: moment().toISOString(),
        collapsedPools: this.collapsedPools,
      };

      let roundsManagerState = localStorage.getItem("roundsManagerState")
        ? JSON.parse(localStorage.getItem("roundsManagerState"))
        : [];

      let existingState = roundsManagerState.find(
        (state) => state.eventId == eventId
      );

      roundsManagerState = roundsManagerState.filter((event) => {
        return event.eventId != eventId && moment().isSame(event.date, "day");
      });

      if (existingState) {
        state.overviewCollapsed = existingState.overviewCollapsed;
      }

      roundsManagerState.push(state);

      localStorage.setItem(
        "roundsManagerState",
        JSON.stringify(roundsManagerState)
      );
    },
    poolActionsLocked(poolId) {
      return this.preventFurtherPoolActions.includes(poolId);
    },
    lockPoolActions(poolId) {
      this.preventFurtherPoolActions.push(poolId);
    },
    unlockPoolActions(poolId) {
      this.preventFurtherPoolActions = this.preventFurtherPoolActions.filter(
        (pool) => pool != poolId
      );
    },
    async closePool(poolData, bool) {
      const pool = poolData.pool;
      let additionalQueryItem = "";
      if (bool) {
        additionalQueryItem = ",confirmDnf:true";
      }

      try {
        let closedPool = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation{
            UpdatePoolStatus(poolId:"${pool.id}", status:COMPLETED ${additionalQueryItem}){
              id
            }
          }
          `,
          },
        });

        if (closedPool.data.errors) {
          if (
            closedPool.data.errors.some(
              (error) => error.errorCode == "PLAYERS_MISSING_SCORE"
            )
          ) {
            this.poolToAction = null;
            this.markAllAsDnf = true;
            setTimeout(() => {
              this.$nextTick(() => (this.poolToAction = poolData));
            }, 500);
          }
        } else {
          this.poolToAction = null;
          this.$store.dispatch("loadManagedEvents");
          this.$store.dispatch(
            "loadManagedEventData",
            this.$router.currentRoute.params.eventId
          );

          this.collapsedPools.push(pool.id);
          this.saveCollapsedPools();

          this.$store.dispatch("showMessage", {
            show: true,
            message: "Pool closed",
            type: "success",
          });
        }
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    shotgunStartDisabled(event, round) {
      const groupSize = event.groupSize;
      const playersCount = event.playersCount;
      let holeCount = 0;

      round.pools.forEach(
        (pool) => (holeCount = holeCount + pool.layoutVersion.holes.length)
      );

      const maxPossiblePlayers = holeCount * groupSize;

      return playersCount > maxPossiblePlayers;
    },
    async createGroupsForRound(event, roundIndex, poolIndex) {
      const round = event.rounds[roundIndex];
      const pool = event.rounds[roundIndex].pools[poolIndex];

      try {
        let startHoles = [];

        round.pools.forEach((pool) => {
          if (pool.startMethod == "TEETIME") {
            let startHole = {
              poolId: pool.id,
              holeId: pool.layoutVersion.holes[0].id,
            };

            startHoles.push(startHole);
          }
        });

        if (pool.createGroupsMethod == "GROUPS_ONLY") {
          for (
            let index = 0;
            index < pool.layoutVersion.holes.length;
            index++
          ) {
            const holeId =
              pool.startMethod == "SHOTGUN"
                ? pool.layoutVersion.holes[index].id
                : pool.layoutVersion.holes[0].id;

            let groupCreation = await this.$axios({
              headers: {
                Authorization: this.$store.state.user.sessionInfo.token,
              },
              data: {
                query: `
                  mutation CreateGroup($CreateGroup: GroupInput!){
                    CreateGroup(poolId:"${pool.id}",input:$CreateGroup){
                      id
                    }
                  }
                `,
                variables: {
                  CreateGroup: {
                    startHoleId: holeId,
                  },
                },
              },
            });
            if (groupCreation.data.errors) {
              const errorCode = groupCreation.data.errors[0].errorCode;
              if (errorCode == "INVALID_START_METHOD") {
                this.$store.dispatch("showMessage", {
                  show: true,
                  message: "Unable to create groups",
                  type: "error",
                });
              }
            }
          }
        } else {
          let groupCreation = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
              mutation CreateGroups($startHoles: [PoolStartHoleInput!]){
                CreateGroups(roundId:"${round.id}",creationMethod:${pool.createGroupsMethod}, startHoles: $startHoles){
                  id
                }
              }
              `,
              variables: {
                startHoles,
              },
            },
          });

          if (groupCreation.data.errors) {
            const errorCode = groupCreation.data.errors[0].errorCode;
            if (errorCode == "INVALID_START_METHOD") {
              this.$store.dispatch("showMessage", {
                show: true,
                message: "All pools must have same starting method",
                type: "error",
              });
            } else if (errorCode == "TOO_MANY_PLAYERS") {
              this.$store.dispatch("showMessage", {
                show: true,
                message: "Too many players. Change settings",
                type: "error",
              });
            } else if (errorCode == "INVALID_START_HOLES") {
              this.$store.dispatch("showMessage", {
                show: true,
                message: "Invalid starting hole for Teetime",
                type: "error",
              });
            } else if (
              errorCode ==
              "SELECTED_CREATION_METHOD_CANT_BE_USED_ON_FIRST_ROUND"
            ) {
              this.$store.dispatch("showMessage", {
                show: true,
                message: "Invalid creation mode for round 1",
                type: "error",
              });
            } else if (
              errorCode ==
              "SELECTED_CREATION_METHOD_CANT_BE_USED_ON_FIRST_ROUND"
            ) {
              this.$store.dispatch("showMessage", {
                show: true,
                message: "Invalid creation mode for round 1",
                type: "error",
              });
            } else if (errorCode == "INVALID_NUMBER_OF_POOLS") {
              this.$store.dispatch("showMessage", {
                show: true,
                message: "Invalid number of pools for creation method",
                type: "error",
              });
            }

            this.unlockPoolActions(pool.id);

            this.$store.dispatch(
              "loadManagedEventData",
              this.$router.currentRoute.params.eventId
            );
          }
        }
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }

      this.unlockPoolActions(pool.id);

      this.$store.dispatch(
        "loadManagedEventData",
        this.$router.currentRoute.params.eventId
      );
    },
    async createGroupsForPool(event, roundIndex, poolIndex) {
      const round = event.rounds[roundIndex];
      const pool = event.rounds[roundIndex].pools[poolIndex];
      const previousPool = event.rounds[roundIndex - 1]?.pools[poolIndex];

      if (pool.createGroupsMethod == "GROUPS_ONLY") {
        // Building a one time query since we can't create the emptry groups hre through the API. Individual calls take too long.
        let mutationParameters = "";
        let query = "";
        let variables = {};

        for (let index = 0; index < pool.layoutVersion.holes.length; index++) {
          const holeId =
            pool.startMethod == "SHOTGUN"
              ? pool.layoutVersion.holes[index].id
              : pool.layoutVersion.holes[0].id;

          mutationParameters =
            mutationParameters + `$CreateGroup${index}: GroupInput!,`;
          query =
            query +
            `CG${index}:CreateGroup(poolId:"${pool.id}",input:$CreateGroup${index}){id}`;

          variables["CreateGroup" + index] = {
            startHoleId: holeId,
          };
        }
        let groupCreation = await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
                  mutation CreateGroup(${mutationParameters}){
                    ${query}
                  }
                `,
            variables,
          },
        });
      } else if (
        pool.createGroupsMethod == "RANDOM" ||
        pool.createGroupsMethod == "SCOREANDDIVISION"
      ) {
        let groupCreation = await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
                mutation{
                  CreateGroupsForPool(poolId:"${pool.id}",previousPoolId:"${previousPool.id}",creationMethod:${pool.createGroupsMethod},startHoleId:"${pool.layoutVersion.holes[0].id}"){
                    id
                  }
                }
              `,
          },
        });
      }

      this.unlockPoolActions(pool.id);

      this.$store.dispatch(
        "loadManagedEventData",
        this.$router.currentRoute.params.eventId
      );
    },
    createGroupActions(event, roundIndex, poolIndex) {
      const round = event.rounds[roundIndex];
      const previousRound = event.rounds[roundIndex - 1];
      const previousPool = event.rounds[roundIndex - 1]?.pools[poolIndex];
      let roundHasNoGroups = true;
      let previousRoundHasCompletedPools = false;

      round.pools.forEach((pool) => {
        if (pool.groups.length > 0) roundHasNoGroups = false;
      });
      previousRound?.pools.forEach((pool) => {
        if (pool.status == "COMPLETED") previousRoundHasCompletedPools = true;
      });

      if (roundIndex == 0) {
        if (roundHasNoGroups) {
          this.createGroupsForRound(event, roundIndex, poolIndex);
        } else {
          this.createGroupsForPool(event, roundIndex, poolIndex);
        }
      }

      if (roundIndex > 0) {
        if (previousRound.status == "COMPLETED") {
          if (roundHasNoGroups) {
            this.createGroupsForRound(event, roundIndex, poolIndex);
          } else {
            this.createGroupsForPool(event, roundIndex, poolIndex);
          }
        } else {
          if (previousPool.status == "COMPLETED") {
            this.createGroupsForPool(event, roundIndex, poolIndex);
          } else if (previousRoundHasCompletedPools) {
            this.createGroupsForPool(event, roundIndex, poolIndex);
          } else if (roundHasNoGroups) {
            this.createGroupsForRound(event, roundIndex, poolIndex);
          } else {
            this.createGroupsForPool(event, roundIndex, poolIndex);
          }
        }
      }
    },
    availableSortOptions(event, roundIndex, poolIndex) {
      const registrationMethod = event.tour.registrationMethod;
      const round = event.rounds[roundIndex];
      const pool = event.rounds[roundIndex].pools[poolIndex];
      const previousRound = event.rounds[roundIndex - 1];
      const previousPool = event.rounds[roundIndex - 1]?.pools[poolIndex];
      let roundHasNoGroups = true;
      let previousRoundHasCompletedPools = false;
      let availableOptions = [];

      round.pools.forEach((pool) => {
        if (pool.groups.length > 0) roundHasNoGroups = false;
      });
      previousRound?.pools.forEach((pool) => {
        if (pool.status == "COMPLETED") previousRoundHasCompletedPools = true;
      });

      if (roundIndex == 0) {
        if (roundHasNoGroups && registrationMethod == "LIST") {
          availableOptions.push({
            title: "Randomise",
            description: "Add players randomly",
            value: "RANDOM",
            disabled: false,
          });
          availableOptions.push({
            title: "Randomise within division",
            description: "Add players randomly within division",
            value: "RANDOM_WITHIN_DIVISION",
            disabled: false,
          });
        } else {
          pool.createGroupsMethod = "GROUPS_ONLY";
        }

        availableOptions.push({
          title: "Empty groups",
          description: "Manually add players",
          value: "GROUPS_ONLY",
          disabled: false,
        });
      }

      if (roundIndex > 0) {
        if (previousRound.status == "COMPLETED") {
          if (roundHasNoGroups) {
            if (round.pools.length > 1) {
              availableOptions.push({
                title: "Randomise",
                description: "Keep pools",
                value: "RANDOM_WITHIN_POOL",
                disabled: false,
              });
              availableOptions.push({
                title: "Randomise",
                description: "Merge pools",
                value: "RANDOM",
                disabled: false,
              });
              availableOptions.push({
                title: "Randomise within division",
                description: "Merge pools",
                value: "RANDOM_WITHIN_DIVISION",
                disabled: false,
              });
              availableOptions.push({
                title: "Division then Result",
                description: "Keep pools",
                value: "SCOREANDDIVISION_WITHIN_POOL",
                disabled: false,
              });
              availableOptions.push({
                title: "Division then Result",
                description: "Merge pools",
                value: "SCOREANDDIVISION",
                disabled: false,
              });
              availableOptions.push({
                title: "Same groups",
                description: "Keep pools",
                value: "SAME_GROUPS",
                disabled: false,
              });
              availableOptions.push({
                title: "Empty groups",
                description: "Manually add players",
                value: "GROUPS_ONLY",
                disabled: false,
              });
            } else {
              availableOptions.push({
                title: "Randomise",
                description: "Add players to groups",
                value: "RANDOM",
                disabled: false,
              });
              availableOptions.push({
                title: "Randomise within division",
                description: "Add players randomly within division",
                value: "RANDOM_WITHIN_DIVISION",
                disabled: false,
              });
              availableOptions.push({
                title: "Division then Result",
                description: "Add players to groups",
                value: "SCOREANDDIVISION",
                disabled: false,
              });
              availableOptions.push({
                title: "Same groups",
                description: "Keep groups from last round",
                value: "SAME_GROUPS",
                disabled: false,
              });
              availableOptions.push({
                title: "Empty groups",
                description: "Manually add players",
                value: "GROUPS_ONLY",
                disabled: false,
              });
            }
          } else {
            pool.createGroupsMethod = "GROUPS_ONLY";
            availableOptions.push({
              title: "Randomise",
              description: `From R${roundIndex}-P${this.$store.state.poolConversion[poolIndex]}`,
              value: "RANDOM",
              disabled: false,
            });

            availableOptions.push({
              title: "Division then Result",
              description: `From R${roundIndex}-P${this.$store.state.poolConversion[poolIndex]}`,
              value: "SCOREANDDIVISION",
              disabled: false,
            });

            availableOptions.push({
              title: "Same groups",
              description: `From R${roundIndex}-P${this.$store.state.poolConversion[poolIndex]}`,
              value: "SAME_GROUPS",
              disabled: false,
            });

            availableOptions.push({
              title: "Empty groups",
              description: "Manually add players",
              value: "GROUPS_ONLY",
              disabled: false,
            });
          }
        } else {
          if (previousPool.status == "COMPLETED") {
            availableOptions.push({
              title: "Randomise",
              description: `From R${roundIndex}-P${this.$store.state.poolConversion[poolIndex]}`,
              value: "RANDOM",
              disabled: false,
            });

            availableOptions.push({
              title: "Division then Result",
              description: `From R${roundIndex}-P${this.$store.state.poolConversion[poolIndex]}`,
              value: "SCOREANDDIVISION",
              disabled: false,
            });

            availableOptions.push({
              title: "Same groups",
              description: `From R${roundIndex}-P${this.$store.state.poolConversion[poolIndex]}`,
              value: "SAME_GROUPS",
              disabled: false,
            });

            availableOptions.push({
              title: "Empty groups",
              description: "Manually add players",
              value: "GROUPS_ONLY",
              disabled: false,
            });
          } else if (previousRoundHasCompletedPools) {
            pool.createGroupsMethod = "GROUPS_ONLY";
            availableOptions.push({
              title: "Randomise",
              description: `From R${roundIndex}-P${this.$store.state.poolConversion[poolIndex]}`,
              value: "RANDOM",
              disabled: true,
            });

            availableOptions.push({
              title: "Division then Result",
              description: `From R${roundIndex}-P${this.$store.state.poolConversion[poolIndex]}`,
              value: "SCOREANDDIVISION",
              disabled: true,
            });
            availableOptions.push({
              title: "Same groups",
              description: `From R${roundIndex}-P${this.$store.state.poolConversion[poolIndex]}`,
              value: "SAME_GROUPS",
              disabled: false,
            });
            availableOptions.push({
              title: "Empty groups",
              description: "Manually add players",
              value: "GROUPS_ONLY",
              disabled: false,
            });
          } else if (roundHasNoGroups) {
            if (round.pools.length > 1) {
              availableOptions.push({
                title: "Randomise",
                description: "Merge pools",
                value: "RANDOM",
                disabled: false,
              });

              availableOptions.push({
                title: "Randomise within division",
                description: "Merge pools",
                value: "RANDOM_WITHIN_DIVISION",
                disabled: false,
              });

              availableOptions.push({
                title: "Randomise",
                description: "Keep pools",
                value: "RANDOM_WITHIN_POOL",
                disabled: false,
              });
              availableOptions.push({
                title: "Division then Result",
                description: "Keep pools",
                value: "SCOREANDDIVISION_WITHIN_POOL",
                disabled: true,
              });
              availableOptions.push({
                title: "Division then Result",
                description: "Merge pools",
                value: "SCOREANDDIVISION",
                disabled: true,
              });
              availableOptions.push({
                title: "Same groups",
                description: "Keep pools",
                value: "SAME_GROUPS",
                disabled: false,
              });
              availableOptions.push({
                title: "Empty groups",
                description: "Manually add players",
                value: "GROUPS_ONLY",
                disabled: false,
              });
            } else {
              availableOptions.push({
                title: "Randomise",
                description: "Add players randomly",
                value: "RANDOM",
                disabled: false,
              });

              availableOptions.push({
                title: "Randomise within division",
                description: "Add players randomly within division",
                value: "RANDOM_WITHIN_DIVISION",
                disabled: false,
              });

              availableOptions.push({
                title: "Same groups",
                description: "Same groups as last round",
                value: "SAME_GROUPS",
                disabled: false,
              });

              availableOptions.push({
                title: "Empty groups",
                description: "Manually add players",
                value: "GROUPS_ONLY",
                disabled: false,
              });
            }
          } else {
            pool.createGroupsMethod = "GROUPS_ONLY";
            if (round.pools.length > 1) {
              availableOptions.push({
                title: "Empty groups",
                description: "Manually add players",
                value: "GROUPS_ONLY",
                disabled: false,
              });
            } else {
              availableOptions.push({
                title: "Empty groups",
                description: "Manually add players",
                value: "GROUPS_ONLY",
                disabled: false,
              });
            }
          }
        }
      }

      return availableOptions;
    },
    canGroupsBeCreatedForRound(round) {
      return round.pools.every((pool) => pool.status == "CLOSED");
    },
    canGroupsBeCreatedForPool(pool, poolIndex, roundIndex) {
      if (roundIndex == 0) return true;
      else if (
        this.eventData.rounds[roundIndex - 1]?.pools[poolIndex].status ==
        "COMPLETED"
      )
        return true;

      return false;
    },
    parCalc(layout) {
      let par = 0;

      layout.holes.forEach((hole) => {
        par += hole.par;
      });

      return par;
    },
    toggleLayoutReview(layoutId) {
      if (this.expandedLayoutReviews.includes(layoutId)) {
        this.expandedLayoutReviews = this.expandedLayoutReviews.filter(
          (id) => id != layoutId
        );
      } else {
        this.expandedLayoutReviews.push(layoutId);
      }
    },
    newLayoutVersionAvailable(layoutVersion) {
      return layoutVersion.id !== layoutVersion.layout.latestVersion.id;
    },
    parEval(hole) {
      if (hole.par <= 3) {
        return "low";
      } else if (hole.par == 4) {
        return "mid";
      } else return "high";
    },
    displayHole(hole) {
      return hole.name == null ? hole.number : hole.name;
    },
    wrapHoles(holes) {
      let wrap = false;

      if (holes.length > 27) {
        wrap = true;
      } else if (holes.length > 18) {
        holes.forEach((hole) => {
          if (hole.name != null) {
            wrap = true;
          }
        });
      }

      return wrap;
    },
    async deleteRound(roundId) {
      try {
        let deleteRound = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation {
              DeleteRound(roundId:"${roundId}")
            }
            `,
          },
        });
        this.$refs.deleteround.close();
        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Round removed",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Something went wrong",
          type: "error",
        });
      }
    },
    async addRound() {
      try {
        let createRound = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation {
              CreateRound(eventId:"${this.eventData.id}",asRole:TOUR_ADMIN){
                id
              }
            }
            `,
          },
        });

        let newRoundId = createRound.data.data.CreateRound.id;
        let lastRound = this.eventData.rounds[this.eventData.rounds.length - 1];

        for (const pool of lastRound.pools) {
          let poolDate = moment(pool.date).add(1, "days").toISOString();
          let poolLayout = pool.layoutVersion.id;

          let createPool = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                    mutation Createpool($CreatePool: PoolInput!){
                      CreatePool(roundId: "${newRoundId}", input: $CreatePool){
                        id
                      }
                    }
                    `,
              variables: {
                CreatePool: {
                  date: poolDate,
                  layoutVersionId: poolLayout,
                },
              },
            },
          });
        }

        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Round added",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Something went wrong",
          type: "error",
        });
      }
    },
    async finalizeGroups(poolToAction, disabled) {
      if (disabled) {
        return;
      }

      let queryText = "mutation {";

      poolToAction.round.pools.forEach((pool, index) => {
        queryText += `P${index}:UpdatePoolStatus(poolId:"${pool.id}",status:PREPARE){id} `;
      });

      queryText += "}";

      try {
        await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: queryText,
          },
        });
        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );

        this.poolToAction = null;
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async startPool(pool, disabled) {
      if (disabled) {
        return;
      }

      try {
        await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
              UpdateEvent(eventId:"${this.$router.currentRoute.params.eventId}", input:$UpdateEvent){
                id
              }
              UpdatePoolStatus(poolId:"${pool.id}",status:OPEN){
                id
              }
            }
            `,
            variables: {
              UpdateEvent: {
                isRegistrationOpen: false,
              },
            },
          },
        });
        this.poolToAction = null;
        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    poolGroupsInfo(groups) {
      let groupsInformation = {
        players: 0,
        playersText: "",
        groups: 0,
        groupsText: "",
        dnf: 0,
        dnfText: "",
        signed: 0,
        signedText: "",
        unsigned: 0,
        unsignedText: "",
        finishedGroups: 0,
        finishedGroupsText: "",
        ongoingGroups: 0,
        ongoingGroupsText: "",
        allGroupsFinished: false,
        allGroupsOngoing: false,
      };

      groups.forEach((group) => {
        if (group.status == "OPEN" && group.playerConnections.length > 0)
          groupsInformation.ongoingGroups++;
        if (group.status == "DONE" && group.playerConnections.length > 0)
          groupsInformation.finishedGroups++;
        if (group.playerConnections.length > 0) groupsInformation.groups++;

        group.playerConnections.forEach((player) => {
          groupsInformation.players++;

          if (player.player.dnf.isDnf || player.player.dns.isDns) {
            groupsInformation.dnf++;
          } else {
            player.hasSignedResult
              ? groupsInformation.signed++
              : groupsInformation.unsigned++;
          }
        });
      });

      if (groupsInformation.groups == groupsInformation.finishedGroups) {
        groupsInformation.allGroupsFinished = true;
      }

      if (
        groupsInformation.groups == groupsInformation.ongoingGroups &&
        groupsInformation.groups > 0
      ) {
        groupsInformation.allGroupsOngoing = true;
      }

      groupsInformation.ongoingGroups == 1
        ? (groupsInformation.ongoingGroupsText = "group live")
        : (groupsInformation.ongoingGroupsText = "groups live");

      groupsInformation.dnf == 1
        ? (groupsInformation.dnfText = "player DNF")
        : (groupsInformation.dnfText = "players DNF");

      groupsInformation.unsigned == 1
        ? (groupsInformation.unsignedText = "player unsigned")
        : (groupsInformation.unsignedText = "players unsigned");

      groupsInformation.players == 1
        ? (groupsInformation.playersText = "Player")
        : (groupsInformation.playersText = "Players");

      groupsInformation.groups == 1
        ? (groupsInformation.groupsText = "Group")
        : (groupsInformation.groupsText = "Groups");

      return groupsInformation;
    },
    async togglePublicGroups(pool) {
      try {
        let toggled = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation UpdatePool($UpdatePool: UpdatePoolInput!){
            UpdatePool(poolId:"${pool.id}", input:$UpdatePool){
              id
            }
          }
          `,
            variables: {
              UpdatePool: {
                areGroupsPublic: pool.areGroupsPublic,
              },
            },
          },
        });
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });

        pool.areGroupsPublic = !pool.areGroupsPublic;
      }
    },
    toggleCollapsedPool(poolId) {
      if (this.collapsedPools.includes(poolId)) {
        this.collapsedPools = this.collapsedPools.filter((id) => id != poolId);
        this.saveCollapsedPools();
      } else {
        this.collapsedPools.push(poolId);
        this.saveCollapsedPools();
      }
    },
    async udpdatePoolLayout(poolId, layoutVersionId) {
      try {
        let update = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation UpdatePool($UpdatePool: UpdatePoolInput!){
              UpdatePool(poolId:"${poolId}", input:$UpdatePool){
                id
              }
            }
            `,
            variables: {
              UpdatePool: {
                layoutVersionId,
              },
            },
          },
        });

        this.expandedLayoutReviews = [];
        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
      } catch (err) {
        console.log(err);

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    toggleCourseSelector(pool) {
      if (pool.status == "CLOSED" && this.editableCourses.includes(pool.id)) {
        if (this.shownCourseSelector == pool.id) {
          this.shownCourseSelector = null;
        } else {
          this.shownCourseSelector = pool.id;
        }
      }
    },
    selectedCourseDisplayFormat(pool) {
      let coursePar = 0;
      let holeCount = 0;

      pool.layoutVersion.holes.forEach((hole) => {
        coursePar += hole.par;
      });

      holeCount = pool.layoutVersion.holes.length;

      return `${pool.layoutVersion.layout.course.name}, ${pool.layoutVersion.layout.name} Par ${coursePar}`;
    },
    toggleVisibleCourseSelectors(poolId) {
      if (this.editableCourses.includes(poolId)) {
        this.editableCourses = this.editableCourses.filter((id) => {
          return id != poolId;
        });
      } else {
        this.editableCourses.push(poolId);
      }
    },
    poolDateTimeOptions(roundIndex, round, poolIndex, pool) {
      let options = {
        timeOnly: false,
        dateOnly: false,
        mode: "grey",
      };

      options.minDateTime = moment().subtract(1, "weeks");
      options.maxDateTime = moment().add(2, "years");

      return options;
    },
    async updatePoolStartMethod(pool, startFormat) {
      try {
        let updatedPool = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation UpdatePool($UpdatePool: UpdatePoolInput!) {
            UpdatePool(poolId: "${pool.id}", input: $UpdatePool) {
              id
            }
          }
          `,
            variables: {
              UpdatePool: {
                startMethod: startFormat,
              },
            },
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });

        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async updatePoolDateTime(newDate, pool) {
      if (moment(newDate).isValid()) {
        pool.date = moment(newDate).toISOString();
      }

      try {
        let updatedPool = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation UpdatePool($UpdatePool: UpdatePoolInput!){
            UpdatePool(poolId:"${pool.id}", input:$UpdatePool){
              id
            }
          }
          `,
            variables: {
              UpdatePool: {
                date: pool.date,
              },
            },
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });

        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    poolDateTime(pool) {
      return moment(pool);
    },
    openPicker(pool) {
      if (this.visiblePoolDatePicker == pool.id) {
        this.visiblePoolDatePicker = null;
      } else {
        this.visiblePoolDatePicker = pool.id;
      }
    },
  },
  beforeMount() {
    this.eventData.rounds.forEach((round) => {
      round.pools.forEach((pool) => {
        if (pool.status == "COMPLETED") this.collapsedPools.push(pool.id);
      });
    });

    if (localStorage.getItem("roundsManagerState")) {
      const roundsManagerState = JSON.parse(
        localStorage.getItem("roundsManagerState")
      );

      const thisEvent = roundsManagerState.find(
        (event) => event.eventId == this.eventData.id
      );

      thisEvent
        ? (this.collapsedPools = thisEvent.collapsedPools)
        : (this.collapsedPools = []);

      this.saveCollapsedPools();
    }
  },
  beforeCreate() {},
  destroyed() {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/EventRoundStyles.scss";

.layout-review-container {
  margin-top: 24px;
  .update-buttons {
    margin-top: 32px;

    button {
      margin-bottom: 24px;
    }
  }
}
.layout-wrapper {
  border: 1px solid $blizzard;
  padding: 16px 16px 18px 16px;
  border-radius: 6px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;

  .layout-info-tags {
    width: 100%;
    display: flex;
    margin-top: 10px;
    div {
      margin-right: 10px;
    }
  }

  .layout-name {
    font-size: 14px;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    @include Gilroy-Bold;
    font-size: 16px !important;

    svg {
      margin-left: 6px;
    }
  }
  .layout-toggle {
    margin: 0;
    color: $ocean;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .holes {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    margin-top: 16px;

    .hole {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
      }

      .par {
        background: $par;
        width: 100%;
        text-align: center;
        font-size: 12px;
        padding: 4px 0;
      }
      .number {
        margin-bottom: 8px;
        width: 100%;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .layout {
    width: 100%;
    .holes {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;

      &.wrap {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-bottom: 24px;
        margin: 0;

        li {
          width: calc(5.5555555556%);
          flex: unset;
          box-sizing: border-box;
        }
      }

      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid white;
        margin-top: 15px;
        max-width: 40px;

        &:last-child {
          border-right: 0px solid white;
        }
        .hole-name {
          font-size: 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 0;
        }
        .hole-par {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-size: 10px;
          @include Gilroy-Bold;

          padding: 5px 0;

          &.low {
            background-color: $mist;
          }
          &.mid {
            background-color: $sleet;
          }
          &.high {
            background-color: $blizzard;
          }
        }
      }
    }
  }
}
.layout-update-notice {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: #fff7e5;
  margin: 12px -15px 0 -15px;
  cursor: pointer;
  p {
    font-size: 16px;
    @include Gilroy-Bold;
    margin: 0;
    color: $sunrise;
  }
  svg {
    margin-right: 10px;
  }

  span {
    margin-left: auto;
    color: $ocean;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .sub-section {
    .StandardBorderedButton {
      width: 150px !important;
    }
  }

  .layout-update-notice {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: #fff7e5;
    margin: 18px 0;
    p {
      font-size: 16px;
      @include Gilroy-Bold;
      margin: 0;
      color: $sunrise;
    }
    svg {
      height: 24px;
      width: 24px;
      margin-right: 10px;
    }

    span {
      margin-left: auto;
      color: $ocean;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .layout-review-container {
    .layout-name {
      svg {
        width: 15px;
        height: 14px;
        margin-left: 3px;
      }
    }
    .update-buttons {
      display: flex;
      button {
        width: unset !important;
        margin-right: 24px;
      }
    }
  }
}
</style>
