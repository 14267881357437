<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.414"
    height="10.121"
    viewBox="0 0 17.414 10.121"
    :class="[direction]"
  >
    <path
      class="a"
      d="M0,0,8,8l8-8"
      transform="translate(16.707 9.414) rotate(180)"
    />
  </svg>
</template>

<script>
export default {
  name: "CollapseExpandArrow",
  props: ["direction"],
};
</script>

<style lang="scss" scoped>
.a {
  fill: none;
  stroke: #2d323c;
  stroke-width: 2px;
}
svg {
  transition: all ease 0.3s;
}
.up {
}
.down {
  transform: scaleY(-1);
}
</style>