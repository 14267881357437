var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-container",on:{"click":function($event){_vm.showOverlay = true}}},[_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[(_vm.showOverlay)?_c('div',{staticClass:"overlay"},[_c('button',{on:{"click":function($event){return _vm.$router.push({
            name: 'event-groups-round',
            params: { roundId: _vm.roundId },
          })}}},[_vm._v(" Edit groups ")]),_c('button',{staticClass:"cancel",on:{"click":function($event){$event.stopPropagation();_vm.showOverlay = false}}},[_vm._v("Cancel")])]):_vm._e()]),_c('div',{staticClass:"header"},[_c('p',[_vm._v("Hole "+_vm._s(_vm.holeDisplayName(_vm.group.startHole)))])]),(_vm.group.startsAt != null)?_c('div',{staticClass:"tee-time"},[_c('div',{staticClass:"displayed-time"},[_c('p',[_c('b',[_vm._v("Start: "+_vm._s(_vm.startTime))])])])]):_vm._e(),(_vm.group.marshall != null)?_c('div',{staticClass:"current-marshall",attrs:{"title":"Scoring Marshall"}},[_c('Marshall',{staticClass:"icon"}),_c('div',{staticClass:"name"},[_c('p',[_vm._v(_vm._s(_vm.group.marshall.firstName))]),_c('span',[_vm._v(_vm._s(_vm.group.marshall.lastName))])])],1):_vm._e(),_vm._l((_vm.group.playerConnections),function(player){return _c('div',{key:player.id,staticClass:"player",class:{
      selected: _vm.selectedPlayer && _vm.selectedPlayer.id == player.id,
      tapped: _vm.tapNotification == player.id && _vm.selectedPlayer.id != player.id,
    }},[(player.hasSignedResult)?_c('div',{staticClass:"division signed"},[_c('span',[_vm._v("Signed")])]):(player.player.dns.isDns || player.player.dnf.isDnf)?_c('div',{staticClass:"division dnf-dns"},[(player.player.dnf.isDnf)?_c('span',[_vm._v("DNF")]):(player.player.dns.isDns)?_c('span',[_vm._v("DNS")]):_vm._e()]):_c('div',{staticClass:"division"},[_c('span',[_vm._v(_vm._s(player.player.division.type))])]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(player.player.user.firstName)+" "),_c('span',[_vm._v(_vm._s(player.player.user.lastName))])])])}),_vm._l((5 - _vm.group.playerConnections.length),function(emptyPlayers){return _c('div',{key:emptyPlayers,staticClass:"player"},[_vm._m(0,true),_c('div'),_c('span')])}),(
      _vm.group.status == 'CLOSED' &&
      _vm.pool.startMethod == 'TEETIME' &&
      _vm.pool.status == 'OPEN'
    )?_c('div',{staticClass:"footer prepare start-manually",on:{"click":function($event){$event.stopPropagation();return _vm.startGroup(_vm.group)}}},[_c('p',{staticClass:"title"},[_vm._v("Start group")])]):(_vm.group.status == 'CLOSED')?_c('div',{staticClass:"footer prepare"},[_c('p',{staticClass:"title"},[_vm._v("Upcoming")]),_c('span')]):(
      _vm.group.status == 'OPEN' &&
      _vm.isResultSigningEnabled &&
      _vm.showUnsignedBadge.length > 0
    )?_c('div',{staticClass:"footer unsigned"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.showUnsignedBadge.length)+" Unsigned")]),_c('span')]):(_vm.group.status == 'OPEN')?_c('div',{staticClass:"footer ongoing"},[_c('p',{staticClass:"title"},[_vm._v("Live")]),_c('span')]):(_vm.group.status == 'DONE' && _vm.isResultSigningEnabled)?_c('div',{staticClass:"footer done"},[_c('p',{staticClass:"title"},[_vm._v("All signed")])]):(_vm.group.status == 'DONE')?_c('div',{staticClass:"footer done"},[_c('p',{staticClass:"title"},[_vm._v("Finished")]),_c('MidnightCheckmark')],1):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"division none"},[_c('span')])}]

export { render, staticRenderFns }