<template>
  <main>
    <slide-up-down class="layout-picker-slide" :active="edit" :duration="500">
      <section class="tabs">
        <div @click="view = 'PUBLIC'" :class="{ active: view == 'PUBLIC' }">
          Official
        </div>
        <div @click="view = 'CUSTOM'" :class="{ active: view == 'CUSTOM' }">
          Custom
        </div>
      </section>
      <section class="layout-picker">
        <h6 v-if="view == 'PUBLIC'">
          Search and apply a new layout to this
          {{ poollength == 1 ? "round" : "pool" }}
        </h6>
        <section class="search" v-if="view == 'PUBLIC'">
          <input
            type="text"
            class="search-field"
            v-model="searchString"
            placeholder="Search for courses"
            @keydown="debouncedCourseSearch()"
          />
          <Search color="midnight" />
        </section>
        <section class="courses" v-if="view == 'CUSTOM'">
          <TheCourseCard
            :course="course"
            v-for="course in myCourses"
            :key="course.id"
            :applyLayoutEnabled="true"
            @apply="udpdatePoolLayout($event)"
          />
        </section>
        <section class="courses" v-if="view == 'PUBLIC'">
          <TheCourseCard
            :course="course"
            v-for="course in shownPublicCourses"
            :key="course.id"
            :applyLayoutEnabled="true"
            :usersPosition="usersPosition"
            @apply="udpdatePoolLayout($event)"
          />
        </section>

        <p class="foot" v-if="view == 'PUBLIC' && searchString.length > 0">
          If you think a course is incorrect or missing then please send us an
          <a href="mailto:hello@tjing.se">email</a>.
        </p>
        <p class="foot" v-if="view == 'CUSTOM'">
          To create a new custom layout or course use the
          <router-link :to="{ name: 'coursemanagerbeta' }">
            course manager</router-link
          >.
        </p>
      </section>
    </slide-up-down>
    <section class="current-layout">
      <p class="small" v-if="edit"><b>Current layout applied:</b></p>
      <p>
        Course: <span>{{ pool.layoutVersion.layout.course.name }}</span>
      </p>
      <p>
        Layout:
        <span
          >{{ pool.layoutVersion.layout.name }}
          <Verified v-if="pool.layoutVersion.layout.type == 'PUBLIC'" />
          <span
            class="custom-tag"
            v-if="pool.layoutVersion.layout.type == 'CUSTOM'"
            >custom</span
          >
        </span>
      </p>
      <ul class="tags">
        <li class="holes">{{ pool.layoutVersion.holes.length }} Holes</li>
        <li class="par">Par {{ parCalc }}</li>
      </ul>
      <div class="layout">
        <ul
          class="holes"
          :class="{ wrap: wrapHoles(pool.layoutVersion.holes) }"
        >
          <li
            class="hole"
            v-for="hole in pool.layoutVersion.holes"
            :key="hole.id"
            :class="{}"
          >
            <div class="hole-name">{{ displayHole(hole) }}</div>
            <div class="hole-par" :class="parEval(hole)">{{ hole.par }}</div>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>

<script>
import { filter, debounce } from "lodash";
import locationMixin from "@/mixins/locationMixin.js";
import Search from "@/assets/icons/Search";
import Verified from "@/assets/icons/Verified";
import TheCourseCard from "@/components/TheCourseCard";
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "TheRoundsLayoutPicker",
  props: ["poollength", "pool", "edit", "myCourses"],
  components: { Search, TheCourseCard, SlideUpDown, Verified },
  mixins: [locationMixin],
  data() {
    return {
      searchString: "",
      view: "PUBLIC",
      publicCourses: [],
      usersPosition: null,
      loaded: false,
    };
  },
  computed: {
    parCalc() {
      let par = 0;

      this.pool.layoutVersion.holes.forEach((hole) => {
        par += hole.par;
      });

      return par;
    },
    shownPublicCourses() {
      return this.filteredPublicCourses.slice(0, 8);
    },
    filteredPublicCourses() {
      let temp = filter(
        this.publicCourses,
        (o) =>
          o.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
          o.address.toLowerCase().includes(this.searchString.toLowerCase())
      );

      if (this.usersPosition == null) {
        return temp;
      }

      return temp.sort((a, b) => {
        let AeventDistanceFromUser = this.getDistanceInKM(
          a.geolocation.lat,
          a.geolocation.lng,
          this.usersPosition.lat,
          this.usersPosition.lng
        );
        let BeventDistanceFromUser = this.getDistanceInKM(
          b.geolocation.lat,
          b.geolocation.lng,
          this.usersPosition.lat,
          this.usersPosition.lng
        );

        if (AeventDistanceFromUser > BeventDistanceFromUser) return 1;
        if (AeventDistanceFromUser < BeventDistanceFromUser) return -1;
        else return 0;
      });
    },
  },
  methods: {
    debouncedCourseSearch: debounce(
      function () {
        this.courseSearch();
      },
      500,
      { maxWait: 800 }
    ),
    async closestCoursesToUser() {
      let courses = await this.$axios({
        data: {
          query: `
          {
            courses(first: 6, filter:{ geolocation: {lng: ${this.usersPosition.lng},lat:${this.usersPosition.lat}} })
            {
              id
              name
              address
              type
              geolocation{
                lng
                lat
              }
              layouts{
                type
                id
                name
                course{
                  name
                  id
                }
              }
            }
          }
          `,
        },
      });

      this.publicCourses = courses.data.data.courses;
    },
    async courseSearch() {
      if (this.searchString == "") {
        await this.closestCoursesToUser();
      } else {
        let courses = await this.$axios({
          data: {
            query: `
          {
            courses(first: 6, filter:{ search:"${this.searchString}" })
            {
              id
              name
              address
              type
              geolocation{
                lng
                lat
              }
              layouts{
                type
                id
                name
                course{
                  name
                  id
                }
              }
            }
          }
          `,
          },
        });

        this.publicCourses = courses.data.data.courses;
      }
    },
    async udpdatePoolLayout(layout) {
      try {
        let update = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation UpdatePool($UpdatePool: UpdatePoolInput!){
              UpdatePool(poolId:"${this.pool.id}", input:$UpdatePool){
                id
              }
            }
            `,
            variables: {
              UpdatePool: {
                layoutVersionId: layout.latestVersion.id,
              },
            },
          },
        });
        this.$emit("done");
        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
      } catch (err) {
        console.log(err);

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    wrapHoles(holes) {
      let wrap = false;

      if (holes.length > 27) {
        wrap = true;
      } else if (holes.length > 18) {
        holes.forEach((hole) => {
          if (hole.name != null) {
            wrap = true;
          }
        });
      }

      return wrap;
    },
    displayHole(hole) {
      return hole.name == null ? hole.number : hole.name;
    },

    parEval(hole) {
      if (hole.par <= 3) {
        return "low";
      } else if (hole.par == 4) {
        return "mid";
      } else return "high";
    },
  },
  async beforeMount() {
    try {
      this.usersPosition = await this.getLocation();
      await this.closestCoursesToUser();
    } catch (err) {
      if (err.code == 1) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Location services disabled",
          type: "error",
        });
      }
    }
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin: 32px 0 32px 0;

  div {
    font-size: 16px;
    height: 32px;
    width: 92px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
    transition: all ease 0.3s;
    background: $mist;
    cursor: pointer;

    &.active {
      background: $ocean;
      color: white;
      @include Gilroy-Bold;
      &:hover {
        background: $ocean;
        color: white;
        @include Gilroy-Bold;
      }
    }
    &:hover {
      background: $fog;
    }
  }
}
.courses {
  > main {
    margin: 0px 0px 24px 0px;
  }
}

.current-layout {
  margin-top: 16px;
  p {
    font-size: 14px;

    span {
      @include Gilroy-Bold;
      display: inline-flex;
      align-items: center;
      svg {
        margin-left: 3px;
        height: 14px;
        margin-bottom: 2px;
      }
    }
  }
  .custom-tag {
    margin-left: 8px;
    font-size: 6px;
    text-transform: uppercase;
    background-color: $sunset;
    color: white;
    letter-spacing: 0.8px;
    padding: 3px 4px 2px 4px;
    border-radius: 10px;
    height: 12px;
    display: inline-flex;
    margin-bottom: 2px;
  }
}
.search {
  position: relative;
  margin-bottom: 32px;
  svg {
    position: absolute;
    left: 24px;
    top: 16px;
  }
  .search-field {
    width: 100%;
    height: 48px;
    border-radius: 50px;
    border: 1px solid $blizzard;
    background-color: transparent;
    padding-left: 50px;
    padding-right: 50px;
    outline: none;
    transition: all ease 0.3s;
    &::placeholder {
      @include Gilroy-Medium;
      color: $blizzard;
    }
    &:hover,
    &:focus {
      border: 1px solid $strom;
    }
  }
}

.layout-picker {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid $sleet;
  padding-bottom: 8px;

  h6 {
    text-align: center;
    margin: 0px 0 24px 0;
    font-size: 16px;
  }

  .foot {
    font-size: 16px;
    text-align: center;
    margin: 12px 0 24px 0;
    a {
      color: $ocean;
    }
    span {
      color: $ocean;
      cursor: pointer;
    }
  }
}
.tags {
  list-style: none;
  margin: 14px 0 0 0;
  padding: 0;
  display: flex;

  li {
    background: $mist;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid $sleet;
    padding: 3px 5px;
    color: $strom;
    font-size: 12px;
    @include Gilroy-Bold;
    width: auto;

    &.holes {
      color: $ocean;
      border: 1px solid #9be1e4;
      background: #e5f8f8;
    }
  }
}
p {
  margin-bottom: 0px;
  &.small {
    margin-top: 24px;
    font-size: 12px;
  }
}
.layout {
  .holes {
    list-style: none;
    margin: 0px 0 30px 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;

    &.wrap {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-bottom: 24px;
      margin: 0;

      li {
        width: calc(5.5555555556%);
        flex: unset;
        box-sizing: border-box;
      }
    }

    li {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid white;
      margin-top: 15px;
      max-width: 40px;

      &:last-child {
        border-right: 0px solid white;
      }
      .hole-name {
        font-size: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
      }
      .hole-par {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 10px;
        @include Gilroy-Bold;

        padding: 5px 0;

        &.low {
          background-color: $mist;
        }
        &.mid {
          background-color: $sleet;
        }
        &.high {
          background-color: $blizzard;
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 1200px) {
  .tabs {
    justify-content: flex-start;
    padding: 0;
    > div {
      margin-right: 32px;
    }
  }
  .layout-picker {
    h6 {
      text-align: left;
      font-size: 14px;
    }
  }
  .current-layout {
    p {
      font-size: 16px;
      &.small {
        font-size: 14px;
      }
    }
  }
  .layout {
    .holes {
      li {
        border-right: 4px solid white;
        margin-top: 25px;
        .hole-name,
        .hole-par {
          font-size: 14px;
        }
      }
    }
  }

  .courses {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > main {
      cursor: pointer;
      margin: 0px 24px 24px 0px;
      width: calc(50% - 12px);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
