<template>
  <div
    class="event-overview"
    :class="{ collapsed: collapsedElements.includes(eventData.id) }"
  >
    <div class="header" @click="toggleElement(eventData.id)">
      <h4>Overview</h4>
      <CollapseExpandArrow />
    </div>
    <slide-up-down
      :active="collapsedElements.includes(eventData.id) == false"
      :duration="250"
      class="slide-wrapper"
    >
      <section class="main">
        <div class="sub-section">
          <h4>Key features</h4>
          <span class="feature">
            <On v-if="eventData.isVerifyMode" />
            <Off v-else />
            <p>The Verifier</p>
          </span>
          <span class="feature">
            <On v-if="eventData.isResultSigningEnabled" />
            <Off v-else />
            <p>The Signature</p>
          </span>
          <span class="feature">
            <On v-if="eventData.checkInStartsAt != null" />
            <Off v-else />
            <p>Check In</p>
          </span>
        </div>
        <div class="sub-section d-lg-none">
          <h4>
            Players
            <router-link
              :to="{ name: 'event-players', params: { eventId: eventData.id } }"
              class="internalLink"
              >View</router-link
            >
          </h4>
          <span class="feature">
            <ConfirmedPlayers />
            <p>{{ eventData.playersCount }} Confirmed</p>
          </span>
          <span class="feature">
            <WaitingList />
            <p>{{ waitingListLength }} Waiting</p>
          </span>
          <span class="feature" v-if="eventData.isRegistrationOpen">
            <RegistrationOpen />
            <p>Open</p>
          </span>
          <span class="feature" v-else>
            <Off />
            <p>Closed</p>
          </span>
        </div>

        <div class="sub-section">
          <h4>
            Pools
            <TjingClickToolTip
              :forcedSize="'small'"
              v-if="eventData.tour.registrationMethod == 'GROUPS'"
            >
              <div class="how-to">
                <p>
                  Once you open registration you can no longer increase the
                  number of pools.
                </p>
              </div>
            </TjingClickToolTip>
            <template v-if="eventData.status == 'CLOSED'">
              <StandardSmallSolidButton
                :fluid="true"
                @click="arePoolSeditable = !arePoolSeditable"
                :title="arePoolSeditable ? 'Done' : 'Edit'"
              />
            </template>
          </h4>
          <div
            class="feature selectors"
            :class="{ disabled: arePoolSeditable == false }"
          >
            <div
              class="item first"
              :class="{ active: numberofPoolsInEvent == 1 }"
              @click="adjustNumberofPools(1)"
            >
              None
            </div>
            <div
              class="item"
              :class="{
                active: numberofPoolsInEvent == 2,
                disabled: poolCountAvailable < 2,
              }"
              @click="adjustNumberofPools(2)"
            >
              2
            </div>
            <div
              class="item"
              :class="{
                active: numberofPoolsInEvent == 3,
                disabled: poolCountAvailable < 3,
              }"
              @click="adjustNumberofPools(3)"
            >
              3
            </div>
            <div
              class="item"
              :class="{
                active: numberofPoolsInEvent == 4,
                disabled: poolCountAvailable < 4,
              }"
              @click="adjustNumberofPools(4)"
            >
              4
            </div>
            <div
              class="item"
              :class="{
                active: numberofPoolsInEvent == 5,
                disabled: poolCountAvailable < 5,
              }"
              @click="adjustNumberofPools(5)"
            >
              5
            </div>
          </div>
        </div>
      </section>
      <section class="sidebar" :class="[roundStageStyling]">
        <h4 class="d-none d-lg-block">Round stage</h4>
        <template v-for="(round, roundIndex) in eventData.rounds">
          <div
            class="round-stage"
            v-for="(pool, poolIndex) in round.pools"
            :key="pool.id"
          >
            <span :class="[pool.status]">
              <svg
                v-if="pool.status == 'COMPLETED'"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <polyline
                  points="12.95 4.46 6.59 10.83 3.05 7.29"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
              </svg>
            </span>
            <div class="round-stage-wrapper">
              <b v-if="numberofPoolsInEvent == 1">Round {{ roundIndex + 1 }}</b>
              <b v-else
                >R{{ roundIndex + 1 }}-P{{
                  $store.state.poolConversion[poolIndex]
                }}</b
              >
              <p v-if="pool.status == 'CLOSED'">Upcoming</p>
              <p v-if="pool.status == 'PREPARE'">Finalised</p>
              <p v-if="pool.status == 'OPEN'">Live</p>
              <p v-if="pool.status == 'COMPLETED'">Finished</p>
            </div>
          </div>
        </template>
      </section>
    </slide-up-down>
  </div>
</template>

<script>
import TjingClickToolTip from "@/components/UIElements/TjingClickToolTip";
import CollapseExpandArrow from "@/assets/icons/CollapseExpandArrow";
import WaitingList from "@/assets/icons/WaitingList";
import ConfirmedPlayers from "@/assets/icons/ConfirmedPlayers";
import RegistrationOpen from "@/assets/icons/RegistrationOpen";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";
import SlideUpDown from "vue-slide-up-down";
import StandardSmallSolidButton from "@/components/UIElements/StandardSmallSolidButton";
import moment from "moment";

export default {
  name: "EventOverview",
  props: ["eventData"],
  data() {
    return {
      collapsedElements: [],
      arePoolSeditable: false,
    };
  },
  components: {
    CollapseExpandArrow,
    On,
    Off,
    WaitingList,
    ConfirmedPlayers,
    RegistrationOpen,
    TjingClickToolTip,
    SlideUpDown,
    StandardSmallSolidButton,
  },
  computed: {
    poolCountAvailable() {
      let adjustable = 0;
      let groupsCreated = false;

      if (this.eventData.tour.registrationMethod == "GROUPS") {
        this.eventData.rounds[0].pools.forEach((pool) => {
          if (pool.groups.length > 0) {
            groupsCreated = true;
          }
        });

        if (groupsCreated) {
          adjustable = this.eventData.rounds[0].pools.length;
        } else {
          adjustable = 5;
        }
      } else {
        adjustable = 5;
      }

      return adjustable;
    },
    numberofPoolsInEvent() {
      return this.eventData.rounds[0].pools.length;
    },
    waitingListLength() {
      return this.eventData.registrations.filter(
        (registration) => registration.status == "PENDING"
      ).length;
    },
    roundStageStyling() {
      let items = 0;

      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          items++;
        });
      });

      if (items == 1) return "two";
      else if (items == 2) return "two";
      else if (items == 3) return "three";
      else return "many";
    },
  },
  methods: {
    async adjustNumberofPools(newNumberOfPool) {
      if (this.arePoolSeditable && this.poolCountAvailable >= newNumberOfPool) {
        if (this.numberofPoolsInEvent > newNumberOfPool) {
          let interator = this.numberofPoolsInEvent - newNumberOfPool;
          try {
            for (const round of this.eventData.rounds) {
              let promises = [];

              for (let index = 0; index < interator; index++) {
                let poolIdToDelete =
                  round.pools[round.pools.length - (index + 1)].id;

                let deletePool = await this.$axios({
                  headers: {
                    Authorization: this.$store.state.user.sessionInfo.token,
                  },
                  data: {
                    query: `
                    mutation {
                      DeleteGroups(poolId:"${poolIdToDelete}"){
                        id
                      }
                      DeletePool(poolId:"${poolIdToDelete}")
                    }
                    `,
                  },
                });
                promises.push(deletePool);
              }

              const results = await Promise.all(promises);

              this.$store.dispatch("showMessage", {
                show: true,
                message: "Pools removed",
                type: "success",
              });
            }
            this.$store.dispatch(
              "loadManagedEventData",
              this.$router.currentRoute.params.eventId
            );
          } catch (err) {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Something went wrong",
              type: "error",
            });
            console.log(err);
          }
        } else {
          let interator = newNumberOfPool - this.numberofPoolsInEvent;

          try {
            for (const round of this.eventData.rounds) {
              let promises = [];

              for (let index = 0; index < interator; index++) {
                let poolDate = round.pools[round.pools.length - 1].date;
                let poolLayout =
                  round.pools[round.pools.length - 1].layoutVersion.id;

                let createPool = await this.$axios({
                  headers: {
                    Authorization: this.$store.state.user.sessionInfo.token,
                  },
                  data: {
                    query: `
                    mutation Createpool($CreatePool: PoolInput!){
                      CreatePool(roundId: "${round.id}", input: $CreatePool){
                        id
                      }
                    }
                    `,
                    variables: {
                      CreatePool: {
                        date: poolDate,
                        layoutVersionId: poolLayout,
                      },
                    },
                  },
                });

                promises.push(createPool);
              }

              const results = await Promise.all(promises);
            }
            this.$store.dispatch(
              "loadManagedEventData",
              this.$router.currentRoute.params.eventId
            );
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Pools added",
              type: "success",
            });
          } catch (err) {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Something went wrong",
              type: "error",
            });
          }
        }
      } else if (this.arePoolSeditable) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Unable to increase pools",
          type: "error",
        });
      }
    },
    toggleElement(id) {
      if (this.collapsedElements.includes(id)) {
        this.collapsedElements = this.collapsedElements.filter(
          (item) => item != id
        );
      } else {
        this.collapsedElements.push(id);
      }

      this.saveCollapsedItems();
    },
    copyUrl() {
      let emailLink = document.querySelector("#eventURL");
      let range = document.createRange();
      range.selectNode(emailLink);
      window.getSelection().addRange(range);
      try {
        // Now that we've selected the anchor text, execute the copy command
        var successful = document.execCommand("copy");
        this.$store.dispatch("showMessage", {
          show: true,
          message: "URL Copied",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Unable to copy",
          type: "error",
        });
      }

      window.getSelection().removeAllRanges();
    },
    saveCollapsedItems() {
      const eventId = this.eventData.id;
      let state = {
        eventId: eventId,
        date: moment().toISOString(),
        collapsedPools: [],
        overviewCollapsed: this.collapsedElements.includes(this.eventData.id),
      };

      let roundsManagerState = localStorage.getItem("roundsManagerState")
        ? JSON.parse(localStorage.getItem("roundsManagerState"))
        : [];

      let existingState = roundsManagerState.find(
        (eventOverviewState) => eventOverviewState.eventId == eventId
      );

      if (existingState) {
        existingState.overviewCollapsed = this.collapsedElements.includes(
          this.eventData.id
        );
        state = existingState;
      }

      roundsManagerState = roundsManagerState.filter((event) => {
        return event.eventId != eventId && moment().isSame(event.date, "day");
      });

      roundsManagerState.push(state);

      localStorage.setItem(
        "roundsManagerState",
        JSON.stringify(roundsManagerState)
      );
    },
  },
  beforeMount() {
    if (localStorage.getItem("roundsManagerState")) {
      const roundsManagerState = JSON.parse(
        localStorage.getItem("roundsManagerState")
      );

      const thisEvent = roundsManagerState.find(
        (event) => event.eventId == this.eventData.id
      );

      if (thisEvent?.overviewCollapsed) {
        this.collapsedElements.push(thisEvent.eventId);
      }

      this.saveCollapsedItems();
    }
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/EventRoundStyles.scss";

.event-overview {
  display: flex;
  flex-wrap: wrap;

  &.collapsed {
    .header {
      svg {
        transform: rotate(180deg);
        transition: all ease 0.3s;
      }
    }
  }

  .slide-wrapper {
    display: flex;
    flex-direction: column;

    .main {
      order: 2;
    }
    .sidebar {
      order: 1;
    }
  }

  .header {
    display: flex;
    width: 100%;
    order: 0;
    height: 44px;
    width: 100%;
    background-color: $fog;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $midnight;
    padding: 0 15px;
    cursor: pointer;

    h4 {
      font-size: 18px;
      margin: 0;
      @include Gilroy-Bold;
    }
    svg {
      transition: all ease 0.3s;
    }
  }
  section {
    &.main {
      width: 100%;
      order: 3;

      .sub-section {
        padding: 20px 15px;
        border-top: 1px solid $sleet;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        &:first-of-type {
          border-top: 0px solid $mist;
        }

        .internalLink {
          margin-left: 0;
        }

        h4 {
          width: 100%;
          margin: 0;
          font-size: 16px;
          @include Gilroy-Bold;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .tooltip-container {
            margin-right: auto;
            margin-left: 5px;
            height: 16px;
            width: 16px;
            z-index: 1;
          }

          a {
            font-size: 16px;
          }
        }

        .feature {
          display: flex;
          align-items: center;
          margin-right: 20px;
          margin-top: 20px;

          &:last-of-type {
            margin-right: 0;
          }
          p {
            margin: 0;
            font-size: 14px;
          }
          svg {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }
          a {
            font-size: 14px;
          }

          &.selectors {
            .item.disabled {
              border: 1px solid $fog;
              color: $fog;
              cursor: default;
              &:not(.active):hover {
                color: $fog;
                border-color: $fog;
              }
            }
            &.disabled {
              .active {
                opacity: 0.2;
              }

              .item {
                border: 1px solid $fog;
                color: $fog;
                cursor: default;
                &:not(.active):hover {
                  color: $fog;
                  border-color: $fog;
                }
              }
            }
            .item {
              width: 40px;
              height: 40px;
              border-radius: 4px;
              border: 1px solid $blizzard;
              color: $blizzard;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              @include Gilroy-Bold;
              margin-right: 12px;
              transition: all ease 0.3s;
              user-select: none;
              cursor: pointer;

              &.active {
                background-color: $twilight;
                border-color: $twilight;
                color: white;
                cursor: default;
              }

              &:not(.active):hover {
                color: $midnight;
                border-color: $midnight;
              }

              &.first {
                width: 60px;
              }
            }
          }
        }
      }
    }
    &.sidebar {
      width: 100%;
      order: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.hidden {
        display: none;
      }
      &.two {
        justify-content: space-around;
      }
      &.three {
        justify-content: space-between;
      }
      &.many {
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .round-stage {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        min-width: 25%;

        span {
          display: flex;
          height: 24px;
          width: 24px;
          border: none;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          order: 3;
          &.CLOSED {
            background-color: $sunrise;
          }
          &.PREPARE {
            background-color: $sunset;
          }
          &.OPEN {
            background-color: $grass;
          }
          &.COMPLETED {
            background-color: $midnight;
          }
        }

        .round-stage-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        b {
          font-size: 12px;
          order: 1;
        }
        p {
          font-size: 12px;
          order: 2;
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .event-overview {
    background-color: white;
    box-shadow: 0 0 2px $fog;
    border: 1px solid $fog;
    border-radius: 6px;

    .slide-wrapper {
      display: flex;
      width: 100%;
      flex-direction: row;

      .main {
        order: 1;
      }
      .sidebar {
        order: 2;
      }
    }

    .header {
      height: 48px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      h4 {
        font-size: 24px;
      }
      svg {
        width: 24px;
        height: 12px;
      }
    }
    section {
      &.main {
        width: calc(100% - 230px);
        order: 2;

        .sub-section {
          background-color: white;
          padding: 30px;
          border-top: 2px solid $mist;
          &:last-of-type {
            border-bottom-left-radius: 6px;
          }
          h4 {
            font-size: 20px;
          }

          .feature {
            margin-top: 24px;
            margin-right: 48px;
            p {
              font-size: 16px;
            }
            svg {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
            a {
              font-size: 16px;
            }
          }
        }
      }
      &.sidebar {
        width: 230px;
        order: 3;
        flex-direction: column;
        align-items: flex-start;
        border-bottom-right-radius: 6px;
        background-color: $snow;
        &.two {
          justify-content: flex-start;
        }
        &.three {
          justify-content: flex-start;
        }
        &.many {
          justify-content: flex-start;
        }

        &.hidden {
          display: flex;
          background-color: $snow;

          h4 {
            display: none !important;
          }
          .round-stage {
            display: none;
          }
        }

        h4 {
          font-size: 20px;
          margin-bottom: 35px;
          margin-left: 30px;
          margin-top: 30px;
        }

        .round-stage {
          margin-left: 45px;
          margin-bottom: 30px;
          margin-top: 0;
          flex-direction: row;
          align-items: flex-start;

          b {
            font-size: 16px;
            @include Gilroy-Medium;
          }
          p {
            font-size: 12px;
            margin: 0;
          }

          span {
            order: 1;
            margin-right: 15px;
            width: 22px;
            height: 22px;
            margin-top: 2px;
          }

          .round-stage-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            order: 2;

            b {
              @include Gilroy-Bold;
            }
          }
        }
      }
    }
  }
}
</style>
